import React from 'react';
import PropTypes from 'prop-types';
import { isPublicUrl } from '@f1/shared/src/_helpers';

class Main extends React.PureComponent {
  render () {
    const { children } = this.props;
    return (
      <div
        id="main"
        style={{
          paddingTop: isPublicUrl
          // public url has no header
            ? '0'
          // add padding to account for fixed header
            : 'calc(50px + var(--padding-header) + var(--padding-header))',
          // use flex to ensure the page content fits 100%
          display: 'flex',
          // page content area, styles used to sticky footer
          flexGrow: '1',
          flexShrink: '0',
          flexBasis: 'auto',
          background: 'var(--color-bg)'
        }}
      >
        {React.cloneElement(children)}
      </div>
    );
  }
}

Main.propTypes = {
  children: PropTypes.element
};

Main.defaultProps = {
  children: React.createElement('div')
};

export default Main;
