import { isEmpty } from '@f1/shared/src/_helpers';
// you need to create a reducer for each piece of state in the store.
// reducers take in 2 things.
// 1. the action that happened
// 2. a copy of current state which to update
export const initialState = {
  defaultMerchantGuid: {},
  currentGuid: {
    guid: '',
    dba: ''
  }
};

function activeGuid (state = initialState, action) {
  switch (action.type) {
    case 'ACTIVE_GUID':
      if (action.currentGuid) {
        return {
          ...state,
          ...(isEmpty(state.defaultMerchantGuid) && { defaultMerchantGuid: action.currentGuid }),
          currentGuid: action.currentGuid
        };
      }
      return {
        ...state,
        currentGuid: {
          ...state.currentGuid
        }
      };
    default:
      return state;
  }
}

export default activeGuid;
