import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actionCreators from './redux/actions/actionCreators';
import Main from './Main';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.isAuthenticated,
    data: state.data,
    user: state.user,
    toggleLoader: state.toggleLoader
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
