import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const creatMockMerchantPortalAccess = overrides => ({ // merchant user only
  // by default, all access is true
  dashboardAccess: true,
  delegateAccess: true,
  documentAccess: true,
  financialAccess: true,
  reportAccess: true,
  ...overrides
});

const getMockDelegateInfoMerchant = overrides => ({ // merchant user only
  merchantGuid: getMockValue({ type: 'guid' }),
  dbaName: getMockValue({ type: 'businessName' }),
  ...creatMockMerchantPortalAccess(overrides)
});

export const mockMerchantDelegates = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  return {
    [guid]: [
      {
        email: 'test@test.com',
        merchantDelegateInfo: getMockDelegateInfoMerchant({
          merchantGuid: guid
        })
      },
      {
        email: getMockValue({ type: 'email', userType: 'external' }),
        merchantDelegateInfo: getMockDelegateInfoMerchant({
          merchantGuid: guid
        })
      }
    ]
  };
};

export default mockMerchantDelegates;
