import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockMerchantRefundRes = (body, params, options) => {
  const { guid = 'testGuid' } = options || {};
  const numberProps = { min: 10000, max: 60000 };
  return {
    [guid]: {
      header: {
        label: 'label',
        lines: [],
        biaxial: [],
        currency: true,
        biaxialCurrency: null
      },
      data: [
        { date: 'Feb', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'Mar', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'Apr', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'May', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'Jun', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'Jul', volume: getMockValue(numberProps), count: getMockValue(numberProps) },
        { date: 'Aug', volume: getMockValue(numberProps), count: getMockValue(numberProps) }
      ]
    }
  };
};

export default mockMerchantRefundRes;
