export const mockSignUpPostRes = (body, params, options) => {
  const { email = '' } = body;
  const partnerEmail = 'seanPartnerSignUpTest@test.com';
  let nextStepRes;
  switch (email) {
    // only partners need to verify phone
    case partnerEmail:
      nextStepRes = 'phone_verification';
      break;
    default: nextStepRes = 'email_verification';
  }
  return { nextStep: nextStepRes };
};

export default mockSignUpPostRes;
