import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockSubmittingPartner = (body, params, options) => {
  const { guid = 'testGuid' } = options;
  const mockAddress = getMockValue({ type: 'address' });
  const mockUser = getMockValue({ type: 'user', userType: 'external' });
  return {
    [guid]: {
      partner: {
        name: mockUser.fullName,
        email: mockUser.email,
        phoneNumber: getMockValue({ type: 'bePhone' }),
        address: {
          addressLine1: mockAddress.fullAddress,
          addressLine2: `Apt ${getMockValue({ min: 1 })}`,
          city: mockAddress.cityName,
          state: mockAddress.stateCode,
          postalCode: mockAddress.zipCode,
          country: 'US'
        }
      }
    }
  };
};

export default mockSubmittingPartner;
