// We'll put all our re-used styled components for WEB items in here.
import styled from 'styled-components';

export const NotificationBellWrapper = styled.div`
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(var(--height-header) + 15px);
  left: calc(${props => (props.mouseX ? props.mouseX : 0)}px - 360px);
  border: 4px solid var(--color-filter-bar);
  border-radius: var(--radius-main);
  box-shadow: 0 1px 5px -1px var(--color-light-label);
  min-width: 420px;
  z-index: 11;
  &:before {
    content: " ";
    position: absolute;
    right: 120px;
    top: -19px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid var(--color-filter-bar);
  }
  .header-wrapper {
    display: flex;
    padding: 0.5em 0.3em;
    position: relative;
    background-color: var(--color-filter-bar);
    border-top-right-radius: var(--radius-small);
    border-top-left-radius: var(--radius-small);
    justify-content: space-between;
    align-items: baseline;
    .header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .text {
        color: var(--color-text);
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1;
      }
    }
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2em;
    align-items: center;
  }
  .notifications {
    display: flex;
    flex-wrap: wrap;
    .header {
      flex: 100%;
      background-color: var(--color-bg-alt);
      color: var(--color-light-label);
      font-size: 1rem;
      padding: 0 0.3em;
      line-height: 1.5;
      text-transform: uppercase;
      text-align: left;
    }
    .row {
      display: flex;
      align-items: center;
      flex: calc(50% - 0.4em);
      font-size: 1.6rem;
      line-height: 1.5;
      padding: 0.2em;
      :hover {
        background-color: var(--color-row-hover);
      }
      .count {
        width: 40px;
      }
    }
  }
`;

export default NotificationBellWrapper;
