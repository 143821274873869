import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockNotificationBellGet = (body, params, options) => {
  const mockData = {
    achReject: 0, // Hardcoded for tests
    chargebackHealth: getMockValue({ type: 'number' }),
    justTestProcessing: getMockValue({ type: 'number' }),
    noStarts: 1, // Hardcoded for tests
    stoppedProcessing: getMockValue({ type: 'number' }),
    terminated: getMockValue({ type: 'number' }),
    runningHot: getMockValue({ type: 'number' }),
    approval: 50, // Hardcoded for tests
    decline: getMockValue({ type: 'number' }),
    initialSubmissions: getMockValue({ type: 'number' }),
    pends: getMockValue({ type: 'number' }),
    withdrawn: getMockValue({ type: 'number' })
  };
  return mockData;
};

export default mockNotificationBellGet;
