import { dashboardGuids } from './guids';

// PARTNER PORTAL
export const mockRelationships = {
  relationship01: {
    relationshipId: dashboardGuids.partnerPortal.guid01,
    relationshipName: 'CTUH_ADD_1',
    relationshipCode: '20001',
    riskProfile: 'preferred',
    bankName: 'cynergy',
    processorName: 'firstData',
    parentRelation: null
  },
  relationship02: {
    relationshipId: dashboardGuids.partnerPortal.guid02,
    relationshipName: 'CTUH_ADD_2',
    relationshipCode: '20002',
    riskProfile: 'elevated',
    bankName: 'cynergy',
    processorName: 'firstData',
    parentRelation: null
  },
  relationship03: {
    relationshipId: 'cb6034d6-52a8-41ab-a3f8-1ef6cfb5c3ff',
    relationshipName: 'TPPS_leaderboard 3',
    relationshipCode: '20003',
    riskProfile: 'elevated',
    bankName: 'cynergy',
    processorName: 'firstData',
    parentRelation: dashboardGuids.partnerPortal.guid02
  },
  relationship04: {
    relationshipId: '3fc9a5e6-e256-4a27-8597-4da37683bfbc',
    relationshipName: 'TPPS_leaderboard 2',
    relationshipCode: '20004',
    riskProfile: 'elevated',
    bankName: 'cynergy',
    processorName: 'firstData',
    parentRelation: dashboardGuids.partnerPortal.guid02
  },
  relationship05: {
    relationshipId: 'fd975260-562c-4b23-83a1-bcc712b0cade',
    relationshipName: 'TPPS_leaderboard 1',
    relationshipCode: '20005',
    riskProfile: 'elevated',
    bankName: 'cynergy',
    processorName: 'firstData',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship06: { // Mock relationship used for useStubData=true & FTs
    relationshipId: 'mock-netevia-elevated-relationship',
    relationshipName: 'Mock Netevia Elevated Relationship',
    relationshipCode: '20006',
    riskProfile: 'elevated',
    bankName: 'mvb',
    processorName: 'netevia',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship07: { // Mock relationship used for useStubData=true & FTs
    relationshipId: 'mock-repay-preferred-relationship',
    relationshipName: 'Repay-Preferred',
    relationshipCode: '20007',
    riskProfile: 'preferred',
    bankName: 'mvb',
    processorName: 'repay',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship08: { // Mock relationship used for useStubData=true & FTs
    relationshipId: 'mock-repay-elevated-relationship',
    relationshipName: 'Repay-Elevated',
    relationshipCode: '20008',
    riskProfile: 'elevated',
    bankName: 'mvb',
    processorName: 'repay',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship09: { // FE - Netevia/Elevated (dev)
    relationshipId: dashboardGuids.partnerPortal.guid06,
    relationshipName: 'FE - Netevia/Elevated',
    relationshipCode: '11116',
    riskProfile: 'elevated',
    bankName: 'esquire',
    processorName: 'netevia',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship10: { // FE - Repay/Preferred (dev)
    relationshipId: dashboardGuids.partnerPortal.guid07,
    relationshipName: 'FE - MVB/Repay',
    relationshipCode: '11118',
    riskProfile: 'preferred',
    bankName: 'mvb',
    processorName: 'repay',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship11: { // FE - Netevia/Preferred (dev)
    relationshipId: dashboardGuids.partnerPortal.guid08,
    relationshipName: 'FE - Netevia/Preferred',
    relationshipCode: '11119',
    riskProfile: 'preferred',
    bankName: 'esquire',
    processorName: 'netevia',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship12: { // FE - Priority/Axiom/Elevated
    relationshipId: dashboardGuids.partnerPortal.guid09,
    relationshipName: 'FE - Priority/Axiom/Elevated',
    relationshipCode: '244201',
    riskProfile: 'elevated',
    bankName: 'axiom',
    processorName: 'priority',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship13: { // FE - Priority/Synovus/Elevated
    relationshipId: dashboardGuids.partnerPortal.guid10,
    relationshipName: 'FE - Priority/Synovus/Elevated',
    relationshipCode: '294346',
    riskProfile: 'elevated',
    bankName: 'synovus',
    processorName: 'priority',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship14: { // FE - Priority/Wells Fargo/Elevated
    relationshipId: dashboardGuids.partnerPortal.guid11,
    relationshipName: 'FE - Priority/Wells Fargo/Elevated',
    relationshipCode: '232426',
    riskProfile: 'elevated',
    bankName: 'wells_fargo',
    processorName: 'priority',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship15: { // Repay-Preferred-Inactive
    relationshipId: dashboardGuids.partnerPortal.guid12,
    relationshipName: 'Repay-Preferred-Inactive',
    relationshipCode: '20009',
    riskProfile: 'preferred',
    bankName: 'mvb',
    processorName: 'repay',
    parentRelation: dashboardGuids.partnerPortal.guid01
  },
  relationship16: { // FE - Repay/Preferred - no crab config options
    relationshipId: dashboardGuids.partnerPortal.guid14,
    relationshipName: 'FE - Repay/Preferred - no crab config options',
    relationshipCode: '22365',
    riskProfile: 'preferred',
    bankName: 'mvb',
    processorName: 'repay',
    parentRelation: 'ce8b01ae-2945-41b8-8cdf-83b314b0d04c'
  }
};

// currently just a hardcoded list of ALL relationships
// since this list is returned on sign-in and is user-specific,
// it cannot be generated as a dynamic list for mock data
export const mockRelationshipList = Object.values(mockRelationships).map(r => ({
  title: r.relationshipName,
  value: r.relationshipId,
  parent: r.parentRelation,
  riskProfile: r.riskProfile
}));

const mockedRelationships = [// Mock relationships used for useStubData=true & FTs
  {
    title: mockRelationships.relationship06.relationshipName,
    value: mockRelationships.relationship06.relationshipId,
    relationshipCode: mockRelationships.relationship06.relationshipCode,
    riskProfile: mockRelationships.relationship06.riskProfile
  },
  {
    title: mockRelationships.relationship07.relationshipName,
    value: mockRelationships.relationship07.relationshipId,
    relationshipCode: mockRelationships.relationship07.relationshipCode,
    riskProfile: mockRelationships.relationship07.riskProfile
  },
  {
    title: mockRelationships.relationship08.relationshipName,
    value: mockRelationships.relationship08.relationshipId,
    relationshipCode: mockRelationships.relationship08.relationshipCode,
    riskProfile: mockRelationships.relationship08.riskProfile
  },
  {
    title: mockRelationships.relationship09.relationshipName,
    value: mockRelationships.relationship09.relationshipId,
    relationshipCode: mockRelationships.relationship09.relationshipCode,
    riskProfile: mockRelationships.relationship09.riskProfile
  },
  {
    title: mockRelationships.relationship11.relationshipName,
    value: mockRelationships.relationship11.relationshipId,
    relationshipCode: mockRelationships.relationship11.relationshipCode,
    riskProfile: mockRelationships.relationship11.riskProfile
  },
  {
    title: mockRelationships.relationship12.relationshipName,
    value: mockRelationships.relationship12.relationshipId,
    relationshipCode: mockRelationships.relationship12.relationshipCode,
    riskProfile: mockRelationships.relationship12.riskProfile
  },
  {
    title: mockRelationships.relationship13.relationshipName,
    value: mockRelationships.relationship13.relationshipId,
    relationshipCode: mockRelationships.relationship13.relationshipCode,
    riskProfile: mockRelationships.relationship13.riskProfile
  },
  {
    title: mockRelationships.relationship14.relationshipName,
    value: mockRelationships.relationship14.relationshipId,
    relationshipCode: mockRelationships.relationship14.relationshipCode,
    riskProfile: mockRelationships.relationship14.riskProfile
  },
  {
    title: mockRelationships.relationship15.relationshipName,
    value: mockRelationships.relationship15.relationshipId,
    relationshipCode: mockRelationships.relationship15.relationshipCode,
    riskProfile: mockRelationships.relationship15.riskProfile
  }
];

export const getMockRelationshipList = (options) => {
  // to get a list of relationships for partner portal
  const { isFuncTest = false } = options || {};
  return [
    // due to what mock data has (for FTs) and what real dev has,
    // these relationships are mocked twice to represent values in mock data vs dev
    ...(isFuncTest
      ? [ // guids used in FTs
        {
          title: 'CTUH_ADD_1',
          value: dashboardGuids.partnerPortal.guid01,
          relationshipCode: mockRelationships.relationship01.relationshipCode,
          riskProfile: mockRelationships.relationship01.riskProfile
        },
        {
          title: 'CTUH_ADD_2',
          value: dashboardGuids.partnerPortal.guid02,
          relationshipCode: mockRelationships.relationship02.relationshipCode,
          riskProfile: mockRelationships.relationship02.riskProfile
        },
        ...mockedRelationships
      ]
      : [ // guids used on signin/dev
        {
          title: 'CTUH_ADD_3',
          value: dashboardGuids.partnerPortal.guid05,
          relationshipCode: '297547',
          riskProfile: mockRelationships.relationship01.riskProfile
        },
        {
          title: 'CTUH_ADD_2',
          value: dashboardGuids.partnerPortal.guid04,
          relationshipCode: mockRelationships.relationship02.relationshipCode,
          riskProfile: mockRelationships.relationship02.riskProfile
        },
        {
          title: 'FE - Repay/Preferred',
          value: dashboardGuids.partnerPortal.guid07,
          relationshipCode: mockRelationships.relationship10.relationshipCode,
          riskProfile: mockRelationships.relationship10.riskProfile
        },
        ...mockedRelationships
      ])
  ];
};

export const mockDefaultPostRes = (body, params, options) => {
  // default mock data POST response for request that return 204 status (with no response body)
  const { guid = ['empty'] } = options || {};
  return { [guid]: {} };
};

export default mockRelationshipList;
