import { mockCrabV1ApplicationGet, createMockApplications, createMockApplicationDetails } from '@f1/shared/src/data/crab/mockCrabV1Application';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { dashboardGuids } from '../guids';
import { mockRelationships, getMockRelationshipList } from '../_mockDataHelpers';

export const mockPreferredRelationships = {
  dev: { // For dev/local
    relationshipId: dashboardGuids.partnerPortal.guid05,
    relationshipName: mockRelationships.relationship01.relationshipName,
    relationshipCode: mockRelationships.relationship01.relationshipCode,
    processName: mockRelationships.relationship01.processorName,
    bankName: mockRelationships.relationship01.bankName,
    riskProfile: mockRelationships.relationship01.riskProfile
  },
  funcTest: { // For functional tests
    relationshipId: mockRelationships.relationship07.relationshipId,
    relationshipName: mockRelationships.relationship07.relationshipName,
    relationshipCode: mockRelationships.relationship07.relationshipCode,
    processName: mockRelationships.relationship07.processorName,
    bankName: mockRelationships.relationship07.bankName,
    riskProfile: mockRelationships.relationship07.riskProfile
  },
  defaultProcessor: { // FT
    relationshipId: mockRelationships.relationship01.relationshipId,
    relationshipName: mockRelationships.relationship01.relationshipName,
    relationshipCode: mockRelationships.relationship01.relationshipCode,
    processName: mockRelationships.relationship01.processorName,
    bankName: mockRelationships.relationship01.bankName,
    riskProfile: mockRelationships.relationship01.riskProfile
  },
  repayNoCrabConfig: {
    relationshipId: mockRelationships.relationship16.relationshipId,
    relationshipName: mockRelationships.relationship16.relationshipName,
    relationshipCode: mockRelationships.relationship16.relationshipCode,
    processName: mockRelationships.relationship16.processorName,
    bankName: mockRelationships.relationship16.bankName,
    riskProfile: mockRelationships.relationship16.riskProfile
  }
};

export const mockElevatedRelationships = {
  priorityAxiom: {
    relationshipId: mockRelationships.relationship12.relationshipId,
    relationshipName: mockRelationships.relationship12.relationshipName,
    relationshipCode: mockRelationships.relationship12.relationshipCode,
    processName: mockRelationships.relationship12.processorName,
    bankName: mockRelationships.relationship12.bankName,
    riskProfile: mockRelationships.relationship12.riskProfile
  },
  prioritySynovus: {
    relationshipId: mockRelationships.relationship13.relationshipId,
    relationshipName: mockRelationships.relationship13.relationshipName,
    relationshipCode: mockRelationships.relationship13.relationshipCode,
    processName: mockRelationships.relationship13.processorName,
    bankName: mockRelationships.relationship13.bankName,
    riskProfile: mockRelationships.relationship13.riskProfile
  },
  priorityWellsFargo: {
    relationshipId: mockRelationships.relationship14.relationshipId,
    relationshipName: mockRelationships.relationship14.relationshipName,
    relationshipCode: mockRelationships.relationship14.relationshipCode,
    processName: mockRelationships.relationship14.processorName,
    bankName: mockRelationships.relationship14.bankName,
    riskProfile: mockRelationships.relationship14.riskProfile
  },
  netevia: {
    relationshipId: mockRelationships.relationship09.relationshipId,
    relationshipName: mockRelationships.relationship09.relationshipName,
    relationshipCode: mockRelationships.relationship09.relationshipCode,
    processName: mockRelationships.relationship09.processorName,
    bankName: mockRelationships.relationship09.bankName,
    riskProfile: mockRelationships.relationship09.riskProfile
  },
  defaultProcessor: { // FT
    relationshipId: mockRelationships.relationship02.relationshipId,
    relationshipName: mockRelationships.relationship02.relationshipName,
    relationshipCode: mockRelationships.relationship02.relationshipCode,
    processName: mockRelationships.relationship02.processorName,
    bankName: mockRelationships.relationship02.bankName,
    riskProfile: mockRelationships.relationship02.riskProfile
  }
};

export const staticApps = { // Apps that have specific static fields - used for FTs
  // GET /v1/application, GET /v1/application/detail
  /* ****************** PREFERRED-RISK ******************* */
  1: { // Uncompleted app (preferred risk)
    applicationStatus: 'draft',
    applicationName: 'App 01 - Uncompleted - Electronic - Preferred Risk',
    relationship: mockPreferredRelationships.dev,
    applicationCreatedTimestamp: '2019-12-31T20:22:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  2: { // Completed app (preferred risk)
    applicationStatus: 'approved',
    applicationName: 'App 02 - Completed - Wet - Preferred Risk',
    relationship: mockPreferredRelationships.dev,
    applicationCreatedTimestamp: '2019-12-30T20:22:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:23:00Z',
    applicationCompletedTimestamp: '2019-12-30T20:24:00Z',
    signatureType: 'wet_signature'
  },
  3: { // Uncompleted app (preferred risk) - FOR FT
    applicationStatus: 'draft',
    applicationName: 'App 03 - Uncompleted - Electronic - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:21:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  4: { // Completed app (preferred risk) - FOR FT
    applicationStatus: 'approved',
    applicationName: 'App 04 - Completed - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-30T20:22:00Z',
    applicationSubmittedTimestamp: '2019-12-30T20:23:00Z',
    applicationCompletedTimestamp: '2019-12-30T20:24:00Z',
    signatureType: 'wet_signature'
  },
  5: { // Waiting on signature app (preferred risk) - FOR FT
    applicationStatus: 'waiting_on_initial_signature',
    applicationName: 'App 05 - Initial Signature - Electronic - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:20:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature',
    requiresNewSignature: true
  },
  6: { // Waiting on signature post pends app (preferred risk) - FOR FT
    applicationStatus: 'waiting_on_signature_post_pends',
    applicationName: 'App 06 - Signature Post Pends - Electronic - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:19:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature',
    requiresNewSignature: true
  },
  7: { // Ready to submit (With Files) - Wet Signed - Preferred Risk
    applicationStatus: 'draft',
    applicationName: 'App 07 - Ready to submit (With Files) - Wet - Preferred Risk',
    relationship: mockPreferredRelationships.dev,
    applicationCreatedTimestamp: '2019-12-31T20:18:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  8: { // Ready to submit (With Files) - Wet Signed - Preferred Risk - FOR FT
    applicationStatus: 'draft',
    applicationName: 'App 08 - Ready to submit (With Files) - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:18:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  9: { // Ready to submit (No Pends) - FOR FT
    applicationStatus: 'waiting_on_partner',
    applicationName: 'App 09 - Waiting On Partner (No Pends) - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:17:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  10: { // Not ready, has pends - FOR FT
    applicationStatus: 'waiting_on_partner',
    applicationName: 'App 10 - Waiting On Partner (Has Pends) - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:17:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  11: { // Uncompleted with no signatureType set
    applicationStatus: 'draft',
    applicationName: 'App 11 - Uncompleted - No Signature Type - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: null,
    requiresNewSignature: true
  },
  12: { // status waiting on employee - FOR FT
    applicationStatus: 'waiting_on_app_review',
    applicationName: 'App 12 - Waiting on employee - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:18:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  13: { // Draft - Electronic - Signed (Missing required files)
    applicationStatus: 'draft',
    applicationName: 'App 13 - Draft - Electronic - Signed (Missing required files)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:18:01Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  14: { // status waiting_on_partner - FOR FT
    applicationStatus: 'waiting_on_partner',
    applicationName: 'App 14 - Ready for Resubmit - Wet - Preferred Risk (FT)',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:17:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },

  /* ****************** ELEVATED-RISK ******************* */
  15: { // uncompleted app (elevated risk) with 1 mpa
    applicationStatus: 'draft',
    applicationName: 'App 15 - Uncompleted (1 MPA) - Wet - Elevated Risk',
    relationship: mockElevatedRelationships.netevia,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  16: { // uncompleted app (elevated risk) with 1 mpa FOR FT
    applicationStatus: 'draft',
    applicationName: 'App 16 - Uncompleted (1 MPA) - Wet - Elevated Risk (FT)',
    relationship: mockElevatedRelationships.netevia,
    applicationCreatedTimestamp: '2019-12-31T20:15:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  17: { // uncompleted app (elevated risk) with 1 mpa
    applicationStatus: 'draft',
    applicationName: 'App 17 - Uncompleted - Electronic - Elevated Risk',
    relationship: mockElevatedRelationships.netevia,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  18: { // Draft - Netevia (Wet)
    applicationStatus: 'draft',
    applicationName: 'App 18 - Draft - Netevia - Wet - Elevated Risk',
    relationship: mockElevatedRelationships.netevia,
    applicationCreatedTimestamp: '2019-12-31T20:17:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  19: { // Draft - Netevia (Electronic)
    applicationStatus: 'draft',
    applicationName: 'App 19 - Draft - Netevia - Electronic - Elevated Risk',
    relationship: mockElevatedRelationships.netevia,
    applicationCreatedTimestamp: '2019-12-31T20:18:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },

  /* ****************** DEFAULT PROCESSOR ******************* */
  20: { // FT - Elevated Risk
    applicationStatus: 'draft',
    applicationName: 'App 20 - Draft - Default Processor - Elevated Risk',
    relationship: mockElevatedRelationships.defaultProcessor,
    applicationCreatedTimestamp: '2019-12-31T20:17:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  21: { // FT - Preferred Risk
    applicationStatus: 'draft',
    applicationName: 'App 21 - Draft - Default Processor - Preferred Risk',
    relationship: mockPreferredRelationships.defaultProcessor,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  22: { // FT - request Signature - Electronic
    applicationStatus: 'draft',
    applicationName: 'App 22 - Draft - Electronic - No Files',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  23: { // FT - request Signature - Electronic
    applicationStatus: 'draft',
    applicationName: 'App 23 - Draft - Electronic - All Files',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  24: { // FT - request Signature - Electronic
    applicationStatus: 'draft',
    applicationName: 'App 24 - Draft - Electronic - Already Signed MPAs',
    relationship: mockPreferredRelationships.funcTest,
    applicationCreatedTimestamp: '2019-12-31T20:16:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'electronic_signature'
  },
  25: { // Priority/Axiom/Elevated
    applicationStatus: 'draft',
    applicationName: 'App 25 - Draft - Wet - Priority/Axiom/Elevated',
    relationship: mockElevatedRelationships.priorityAxiom,
    applicationCreatedTimestamp: '2019-12-31T20:15:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  26: { // Priority/Synovus/Elevated
    applicationStatus: 'draft',
    applicationName: 'App 26 - Draft - Wet - Priority/Synovus/Elevated',
    relationship: mockElevatedRelationships.prioritySynovus,
    applicationCreatedTimestamp: '2019-12-31T20:14:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  27: { // Priority/Wells Fargo/Elevated
    applicationStatus: 'draft',
    applicationName: 'App 27 - Draft - Wet - Priority/Wells Fargo/Elevated',
    relationship: mockElevatedRelationships.priorityWellsFargo,
    applicationCreatedTimestamp: '2019-12-31T20:13:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  },
  28: { // FE - Repay/Preferred - no crab config options
    applicationStatus: 'draft',
    applicationName: 'App 28 - Draft - Wet - FE - Repay/Preferred - no crab config options',
    relationship: mockPreferredRelationships.repayNoCrabConfig,
    applicationCreatedTimestamp: '2019-12-31T20:14:00Z',
    applicationSubmittedTimestamp: null,
    applicationCompletedTimestamp: null,
    signatureType: 'wet_signature'
  }
};

// Create guid id (index) to relationship id map so GET application and
// GET application detail uses the same relationship id
export const mockGuidToRelationshipMap = Array.from({ length: 100 })
  .reduce((acc, item, index) => {
    const idNum = index + 1;
    const defaultMockGuid = mockPreferredRelationships.dev.relationshipId;
    return {
      ...acc,
      // if relationship is assigned in `staticApps`, use it
      [idNum]: staticApps[idNum]
        ? staticApps[idNum].relationship?.relationshipId || defaultMockGuid
        : defaultMockGuid
    };
  }, {});

// FTs use static relationshipIds, while dev data can change (eg, when dev data is rebuilt)
// So to have dev + FTs both working, we need to merge the 2 different relationship lists
export const allMockRelationships = [
  ...getMockRelationshipList({ isFuncTest: true }),
  ...getMockRelationshipList({ isFuncTest: false })
];

// Create list of ALL mock applications ONCE so paged data doesn't get recreated multiple times
const mockAllApps = createMockApplications({
  staticApps,
  userType: 'partner',
  relationshipList: allMockRelationships,
  mockGuidToRelationshipMap
});

export const mockPartnerCrabV1ApplicationGet = (body, params, options) => {
  const { guid } = options || {};
  const mockData = mockCrabV1ApplicationGet(body, params, { ...options, mockAllApps, staticApps });
  return { [guid]: mockData };
};

export const mockPartnerCrabV1ApplicationPut = (body, params, options) => {
  const { guid } = options || {};
  const isNeteviaElevatedRisk = guid === mockElevatedRelationships.netevia.relationshipId;
  const mockData = {
    application: {
      // use from staticApps App 19 Draft - Netevia (Electronic)
      applicationId: isNeteviaElevatedRisk ? '19' : getMockValue({ type: 'guid' }),
      applicationMpaId: getMockValue({ type: 'guid' })
    }
  };
  return { [guid]: mockData };
};

export const mockPartnerCrabV1ApplicationMpaPut = (body, params, options) => {
  const { guid } = options || {};
  return { [guid]: { applicationMpaId: getMockValue({ type: 'guid' }) } };
};

export const mockPartnerCrabV1ApplicationDetailsGet = (body, params, options) => {
  const { guid } = options || {};
  const appsWithStaticMpas = {
    // Map of appId (from `staticApps` above) to { mpaIndex: mpaMetadata }
    11: {
      0: { // specify index of the mpa
        // mpa metadata overrides
        dbaName: 'DBA Name 1 - wet-signed (preferred risk)'
      }
    },
    13: {
      0: {
        dbaName: 'DBA Name 1'
      }
    },
    14: {
      0: {
        dbaName: 'DBA Name 1 - Has pends (preferred risk)'
      }
    },
    15: {
      0: {
        dbaName: 'DBA Name 1 - wet-signed (elevated risk)'
      }
    },
    16: {
      0: {
        dbaName: 'DBA Name 1 - wet-signed (elevated risk)'
      }
    },
    17: {
      1: {
        dbaName: 'DBA Name 1 - wet-signed (elevated risk)'
      }
    },
    18: {
      0: {
        dbaName: 'DBA Name 1 - wet-signed (elevated risk)'
      },
      1: {
        dbaName: 'DBA Name 2 - wet-signed (elevated risk)'
      }
    },
    19: {
      0: {
        dbaName: 'DBA Name 1 - electronic-signed (elevated risk)'
      },
      1: {
        dbaName: 'DBA Name 2 - electronic-signed (elevated risk)'
      }
    },
    21: {
      0: { dbaName: 'DBA Name 1 - wet-signed (preferred risk)' }
    },
    22: {
      0: { dbaName: 'DBA Name 1 - electronic (preferred risk)' }
    },
    23: {
      0: { dbaName: 'DBA Name 1 - electronic (preferred risk)' }
    },
    24: {
      0: { dbaName: 'DBA Name 1 - electronic - signed (preferred risk)' },
      1: { dbaName: 'DBA Name 2 - electronic - signed (preferred risk)' }
    }
  };
  const appMatch = mockAllApps.find(app => app.applicationId === guid);
  const mockData = createMockApplicationDetails(guid, {
    mockAppMatch: appMatch,
    relationshipList: allMockRelationships,
    mockGuidToRelationshipMap,
    staticApps,
    appsWithStaticMpas
  });
  return { [guid]: mockData };
};
