import { monthsArray, pad } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

const createMockOnHoldData = options => monthsArray.reduce((acc, month, index) => {
  const { guid, guidKey } = options || {};
  const mockRows = Array.from({ length: guidKey === 'merchantGuid' ? 1 : 5 }).map(() => ({
    merchantGuid: guidKey === 'merchantGuid' ? guid : getMockValue({ type: 'guid' }),
    mid: getMockValue({ type: 'mid' }),
    dbaName: getMockValue({ type: 'businessName' }),
    legalName: getMockValue({ type: 'businessName' }),
    onHoldStartTimeStamp: `2020-${pad(index + 1)}-${pad(getMockValue({ min: 1, max: 20 }))}T14:35:16.950`,
    onHoldReason: getMockValue({ type: 'boolean' }) ? getMockValue({ type: 'string' }) : null
  }));
  return acc.concat(...mockRows);
}, []);

export const mockMerchantOnHoldGet = (body, params, options) => {
  const { guid = '', guidKey } = options || {};
  return { [guid]: { merchantsOnHold: createMockOnHoldData({ guid, guidKey }) } };
};

export default mockMerchantOnHoldGet;
