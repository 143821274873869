import { isEmpty, transformData } from '@f1/shared/src/_helpers';
import React from 'react';

export const notificationsTemplate = {
  toModal: (schema, version) => {
    if (version === '1.0') {
      return Array.isArray(schema) ? schema.map(entry => ({
        ...entry,
        mid: !isEmpty(entry?.mid) ? (<a id={entry.mid} href={`merchantDetails?mid=${encodeURI(entry.mid)}`}>{entry.mid}</a>) : '-'
      })) : [];
    }
    return schema;
  },
  toDataTable: (schema, version) => {
    if (version === '1.0') {
      const formatted = Object.keys(schema).map((key, index) => (
        {
          [key]: transformData({
            data: schema[key],
            toSchema: 'toModal',
            version: '1.0',
            template: notificationsTemplate
          })
        }));
      return Object.assign({}, ...formatted);
    }
    return schema;
  }
};

export default notificationsTemplate;
