import { isEmpty } from '@f1/shared/src/_helpers';

// This holds All the actions that change state as they get fire when a related event happens.

export function authenticate (user) {
  return {
    type: 'AUTHENTICATE',
    payload: user
  };
}

export function resetPassword (bool) {
  return {
    type: 'RESET_PASSWORD',
    payload: bool
  };
}

export function dataUpdate (actions) {
  const {
    merchantData,
    partnerData,
    dataRange,
    delegates
  } = actions;
  return {
    type: 'DATA_UPDATE',
    merchantData,
    partnerData,
    dataRange,
    delegates
  };
}

export function filterData (actions) {
  const {
    filtered,
    activeFilters,
    perPage,
    pageNum,
    report
  } = actions;
  return {
    type: 'FILTER_DATA',
    filtered,
    activeFilters,
    perPage,
    pageNum,
    report
  };
}

export function toggleLoader (loading) {
  return {
    type: 'TOGGLE_LOADER',
    loading
  };
}

export function activeGuid (currentGuid) {
  return {
    type: 'ACTIVE_GUID',
    currentGuid
  };
}

export function activeDownline (actions) {
  const { currentDownlines, dashboardRequestGuids } = actions;
  return {
    type: 'ACTIVE_DOWNLINE',
    currentDownlines,
    dashboardRequestGuids
  };
}

export function alertBar (barStyle, message, timeout) {
  return {
    type: 'ALERT_BAR',
    barStyle,
    message,
    timeout
  };
}

export function appState (actions) {
  const {
    nestedMenus,
    navLinks,
    page,
    userPreferences,
    serverMaintenance
  } = actions;
  return {
    type: 'APP_STATE',
    nestedMenus: nestedMenus || {},
    navLinks: navLinks || {},
    page: page || {},
    userPreferences: userPreferences || {},
    serverMaintenance: !isEmpty(serverMaintenance) ? serverMaintenance : false
  };
}

export function captcha (bool, path, code) {
  return {
    type: 'SET_CAPTCHA',
    bool,
    path,
    code
  };
}

export function notifications (actions) {
  const { notices } = actions;
  const count = notices ? notices.length : 0;
  return {
    type: 'NOTIFICATIONS',
    notices: notices || [],
    count
  };
}

export function resetStore () {
  return {
    type: 'RESET_STORE'
  };
}

export function setSignupInfo (actions) {
  const { email, phone, password } = actions;
  return {
    type: 'SIGNUP_INFO',
    email,
    phone,
    password
  };
}

export function signup (signedUp) {
  return {
    type: 'SIGNUP',
    signedUp
  };
}

export function signupNextStep (nextStep) {
  return {
    type: 'SIGNUP_NEXT_STEP',
    nextStep
  };
}

export function confirmPhone (phoneConfirmed) {
  return {
    type: 'CONFIRM_PHONE',
    phoneConfirmed
  };
}

export function confirmEmail (emailConfirmed) {
  return {
    type: 'CONFIRM_EMAIL',
    emailConfirmed
  };
}

export function setEmail (email) {
  return {
    type: 'SET_EMAIL',
    email
  };
}

export function siteModal (showModal, modalTitle, modalContent, options) {
  return {
    type: 'MODAL',
    showModal,
    modalTitle,
    modalContent,
    options
  };
}

export function updatePage (actions) {
  const {
    pageName,
    dropdown
  } = actions;
  return {
    type: 'UPDATE_PAGE',
    pageName,
    dropdown
  };
}
