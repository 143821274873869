import { transformData } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { notificationsTemplate } from './templates/notificationsTemplate';

export const createMockNotifications = (options) => {
  const { relationshipList = [] } = options || {};
  const mockRelationship = relationshipList[0] || {};
  const mockArrayOptions = { min: 10, max: 30 };
  const mockNoStarts = Array.from({ length: getMockValue(mockArrayOptions) }).map(() => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    return {
      approvalDate: getMockValue({ type: 'date' }),
      mid: getMockValue({ type: 'mid' }),
      dbaName: getMockValue({ type: 'businessName' }),
      merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      partnerName: mockUser.fullName,
      partnerEmail: mockUser.email,
      partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
      legalName: getMockValue({ type: 'businessName' }),
      relationshipCode: mockRelationship.relationshipCode
    };
  });
  const mockNoRealProcessing = Array.from({ length: getMockValue(mockArrayOptions) }).map(() => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    return {
      approvalDate: getMockValue({ type: 'date' }),
      mid: getMockValue({ type: 'mid' }),
      dbaName: getMockValue({ type: 'businessName' }),
      merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      partnerName: mockUser.fullName,
      partnerEmail: mockUser.email,
      partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
      legalName: getMockValue({ type: 'businessName' }),
      relationshipCode: mockRelationship.relationshipCode
    };
  });
  const mockStoppedProcessing = Array.from({ length: getMockValue(mockArrayOptions) }).map(() => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    const mockSalesVolume = getMockValue({ type: 'number', min: 1000, max: 5000 });
    return {
      mid: getMockValue({ type: 'mid' }),
      legalName: getMockValue({ type: 'businessName' }),
      dbaName: getMockValue({ type: 'businessName' }),
      merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      partnerName: mockUser.fullName,
      partnerEmail: mockUser.email,
      partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
      relationshipCode: mockRelationship.relationshipCode,
      lastBatchDate: getMockValue({ type: 'date' }),
      prior30DaySalesVolume: mockSalesVolume,
      prior30DaySalesCount: getMockValue({ type: 'number' }),
      prior30DayAverageTicket: mockSalesVolume / getMockValue({ type: 'number', min: 1 })
    };
  });
  const mockShutdowns = Array.from({ length: getMockValue(mockArrayOptions) }).map(() => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    return {
      closedDate: getMockValue({ type: 'date' }),
      midLifeSpanInDays: getMockValue({ type: 'number' }),
      closureReason: null,
      mid: getMockValue({ type: 'mid' }),
      dbaName: getMockValue({ type: 'businessName' }),
      merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      partnerName: mockUser.fullName,
      partnerEmail: mockUser.email,
      partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
      legalName: getMockValue({ type: 'businessName' }),
      relationshipCode: mockRelationship.relationshipCode
    };
  });
  const mockRunningHot = Array.from({ length: getMockValue(mockArrayOptions) }).map(() => {
    const mockUser = getMockValue({ type: 'user', userType: 'external' });
    return {
      mid: getMockValue({ type: 'mid' }),
      legalName: getMockValue({ type: 'businessName' }),
      dbaName: getMockValue({ type: 'businessName' }),
      merchantEmail: getMockValue({ type: 'email', userType: 'external' }),
      merchantPhoneNumber: getMockValue({ type: 'bePhone' }),
      partnerName: mockUser.fullName,
      partnerEmail: mockUser.email,
      partnerPhoneNumber: getMockValue({ type: 'bePhone' }),
      relationshipCode: mockRelationship.relationshipCode,
      relationshipName: mockRelationship.title,
      approvalDate: getMockValue({ type: 'date' }),
      monthToDateChargebackCountForLegalEntity: getMockValue({ type: 'number' }),
      monthToDateChargebackRatioForLegalEntity: getMockValue({ type: 'ratio' }),
      currentMonthlyVolume: getMockValue({ type: 'number', min: 0, max: 1000000 }),
      currentUsedVolumeRatio: getMockValue({ type: 'ratio' }),
      projectedMonthlyVolumeAtCurrentRunRate: getMockValue({ type: 'number', min: 0, max: 1000000 }),
      totalAllowedMonthlyVolume: getMockValue({ type: 'number', min: 0, max: 1000000 })
    };
  });
  const mockResponse = {
    noStarts: mockNoStarts,
    noRealProcessing: mockNoRealProcessing,
    stoppedProcessing: mockStoppedProcessing,
    shutdowns: mockShutdowns,
    runningHot: mockRunningHot
  };
  return mockResponse;
};

export const mockPartnerNotificationsGetRes = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  const backendData = createMockNotifications(options);
  return {
    [guid]: backendData
  };
};

export const mockPartnerNotificationsFormatted = data => transformData({
  data,
  toSchema: 'toModal',
  version: '1.0',
  template: notificationsTemplate
});

export const mockPartnerNotificationsFormattedDataTable = data => transformData({
  data,
  toSchema: 'toDataTable',
  version: '1.0',
  template: notificationsTemplate
});
