import { isBool } from '@f1/shared/src/_helpers';

export const initialState = {
  signedUp: false,
  nextStep: '',
  phoneConfirmed: false,
  emailConfirmed: false,
  phone: '',
  email: '',
  password: ''
};

function signup (state = initialState, action) {
  switch (action.type) {
    case 'SIGNUP_INFO':
      return {
        ...state,
        ...(action.email && { email: action.email }),
        ...(action.phone && { phone: action.phone }),
        ...(action.password && { password: action.password })
      };
    case 'SIGNUP':
      return {
        ...state,
        signedUp: action.signedUp,
        ...(isBool(action.signedUp) && !action.signedUp && {
          nextStep: initialState.nextStep,
          phoneConfirmed: initialState.phoneConfirmed,
          emailConfirmed: initialState.emailConfirmed,
          phone: initialState.phone,
          email: initialState.email,
          password: initialState.password
        })
      };
    case 'SIGNUP_NEXT_STEP':
      return {
        ...state,
        nextStep: action.nextStep
      };
    case 'CONFIRM_PHONE':
      return {
        ...state,
        phoneConfirmed: action.phoneConfirmed
      };
    case 'CONFIRM_EMAIL':
      return {
        ...state,
        emailConfirmed: action.emailConfirmed
      };
    default:
      return state;
  }
}

export default signup;
