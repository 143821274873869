import { isEmpty } from '@f1/shared/src/_helpers';

export const initialState = {
  masterDownlinesList: [],
  currentDownlines: [],
  dashboardRequestGuids: []
};

function activeDownline (state = initialState, action) {
  switch (action.type) {
    case 'ACTIVE_DOWNLINE':
      return {
        ...state,
        ...(action.currentDownlines && {
          ...(isEmpty(state.masterDownlinesList) && {
            masterDownlinesList: action.currentDownlines
          }),
          currentDownlines: action.currentDownlines
        }),
        ...(action.dashboardRequestGuids && {
          dashboardRequestGuids: action.dashboardRequestGuids
        })
      };
    default:
      return state;
  }
}

export default activeDownline;
