import { transformData } from '@f1/shared/src/_helpers';
import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { delegateTemplateV2 } from './templates/delegateTemplate';

export const mockDataGuids = {
  relationship01: '90290032-6717-475d-9b09-5a0116e167dd',
  relationship02: '1b222b4f-73b6-4b7c-bce9-c3a50cd247d5'
};

export const mockDataKeys = {
  key01: mockDataGuids.relationship01,
  key02: mockDataGuids.relationship02
};

export const createMockPartnerPortalAccessList = overrides => ({ // partner only
  // by default, all access is true
  dashboardAccess: true,
  reportAccess: true,
  residualAccess: true,
  delegateAccess: true,
  applicationAccess: true,
  documentAccess: true,
  varCreationAccess: true,
  maintenanceAccess: true,
  ...overrides
});

const getMockDelegateInfoPartner = overrides => ({ // partner only
  relationshipId: mockDataGuids.relationship01,
  relationshipName: 'CTUH_ADD_1',
  email: getMockValue({ type: 'email', userType: 'external' }),
  ...createMockPartnerPortalAccessList(overrides)
});

export const mockDelegateV2Get = (body, params, options) => {
  const { guid = ['anyGuid'] } = options || {};
  return {
    [guid]: {
      relationshipDelegateInfo: [
        getMockDelegateInfoPartner({
          // static data for tests
          relationshipId: mockDataGuids.relationship01,
          relationshipName: 'CTUH_ADD_1',
          email: 'email@email.com',
          residualAccess: false,
          delegateAccess: false,
          documentAccess: false
        }),
        getMockDelegateInfoPartner({
          // static data for tests
          relationshipId: mockDataGuids.relationship02,
          relationshipName: 'CTUH_ADD_2',
          email: 'example@test.com',
          residualAccess: false,
          delegateAccess: false,
          documentAccess: false
        }),
        getMockDelegateInfoPartner({
          // static data for tests
          relationshipId: mockDataGuids.relationship02,
          relationshipName: 'CTUH_ADD_2',
          email: 'email@email.com',
          residualAccess: false,
          delegateAccess: false,
          documentAccess: false,
          varCreationAccess: false,
          maintenanceAccess: false
        })
      ]
    }
  };
};

export const mockDelegateV2GetFormatted = transformData({
  data: {
    relationships: mockDelegateV2Get({}, {}, { relationshipIdList: ['testGuid'] }).anyGuid.relationshipDelegateInfo
  },
  toSchema: 'frontend',
  template: delegateTemplateV2,
  version: '1.0'
});
