const cbCalculatorTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0') {
      return {
        averageTicket: schema?.averageTicket?.value,
        monthlyVolume: schema?.monthlyVolume?.value,
        discover: {
          cbCount: schema?.discover?.cbCount?.value,
          cbRatio: schema?.discover?.cbRatio?.value,
          salesPercent: schema?.discover?.sales?.value
        },
        masterCard: {
          cbCount: schema?.masterCard?.cbCount?.value,
          cbRatio: schema?.masterCard?.cbRatio?.value,
          salesPercent: schema?.masterCard?.sales?.value
        },
        visa: {
          cbCount: schema?.visa?.cbCount?.value,
          cbRatio: schema?.visa?.cbRatio?.value,
          salesPercent: schema?.visa?.sales?.value
        }
      };
    }
    return schema;
  }
};

export default cbCalculatorTemplate;
