import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { filterByYearMonth } from '@f1/shared/src/_testHelpers';

const headers = { // defaults
  barGraph: {
    label: 'month',
    lines: ['ratio'],
    biaxial: ['ratio'],
    currency: false,
    biaxialCurrency: false
  },
  transactionTable: [
    { order: 1, key: 'merchant.mid', englishName: 'MID' },
    { order: 2, key: 'merchant.legalName', englishName: 'Legal Name' },
    { order: 3, key: 'transactionDateCb', englishName: 'Transaction Date' },
    { order: 4, key: 'receivedDate', englishName: 'Received Date' },
    { order: 5, key: 'transactionTimestampCb', englishName: 'Transaction Timestamp' },
    { order: 6, key: 'receivedTimestamp', englishName: 'Received Timestamp' },
    { order: 7, key: 'transactionAmount', englishName: 'Transaction Amount' },
    { order: 8, key: 'chargebackAmount', englishName: 'Chargeback Amount' },
    { order: 9, key: 'chargebackStatus', englishName: 'Chargeback Status' },
    { order: 10, key: 'cbReferenceNumber', englishName: 'Reference Number' },
    { order: 11, key: 'statusDate', englishName: 'Status Date' },
    { order: 12, key: 'card.cardBrand', englishName: 'Card Brand' },
    { order: 13, key: 'card.cardNumber.cardNumber', englishName: 'Card Number' },
    { order: 14, key: 'chargebackReasonCode', englishName: 'Chargeback Reason Code' },
    { order: 15, key: 'descriptionCb', englishName: 'Description CB' }

  ]
};

const createMockChargebackTableData = (options) => {
  const { length } = options || {};
  const mockChargebacks = Array.from({ length: length || 26 }).map(() => ({
    txn: {
      transactionDateCb: getMockValue({ type: 'date' }),
      receivedDate: getMockValue({ type: 'date' }),
      transactionTimestampCb: getMockValue({ type: 'isoDate' }),
      receivedTimestamp: getMockValue({ type: 'isoDate' }),
      transactionAmount: getMockValue({ type: 'price' }),
      chargebackAmount: getMockValue({ type: 'price' }),
      chargebackStatus: 'Stand',
      cbReferenceNumber: getMockValue({ min: 100000000000, max: 999999999999, stringify: true }),
      statusDate: getMockValue({ type: 'date' }),
      chargebackReasonCode: getMockValue({ type: 'list', list: ['00', '01', '02', '03'] }),
      descriptionCb: getMockValue({ type: 'description' }),
      card: {
        cardNumber: {
          cardNumberId: getMockValue({ type: 'guid' }),
          cardNumber: getMockValue({ type: 'cardNumber' })
        },
        cardBrand: getMockValue({ type: 'cardBrand' })
      },
      merchant: {
        mid: getMockValue({ type: 'mid' }),
        legalName: getMockValue({ type: 'businessName' })
      }
    }
  }));
  return mockChargebacks;
};

export const mockReportChargebackDataBackend = (options) => {
  const numberProps = { type: 'number', min: 1 };
  const ratioProps = { type: 'ratio' };
  const { reportType = 'chargeback' } = options;
  const mockResponse = {
    [`${reportType}BarGraph`]: {
      header: headers.barGraph,
      data: filterByYearMonth([
        { month: '2019-09', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2019-10', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2019-11', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2019-12', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-01', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-02', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-03', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-04', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-05', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-06', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-07', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-08', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-09', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-10', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-11', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2020-12', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2021-01', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2021-02', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) },
        { month: '2021-03', count: getMockValue(numberProps), ratio: getMockValue(ratioProps) }
      ], options)
    },
    [`${reportType}TransactionTable`]: {
      header: headers.transactionTable,
      data: createMockChargebackTableData(options)
    }
  };
  return mockResponse;
};

export const mockReportChargebackGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const backendData = mockReportChargebackDataBackend({ ...options, ...params });
  return {
    [guid]: backendData
  };
};
