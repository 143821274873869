import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { crabAppStatusList } from '@f1/shared/src/_crabFields';
import {
  getNow,
  transformData
} from '@f1/shared/src/_helpers';
import applicationStatusTemplate from './templates/applicationStatusTemplate';
import { getMockRelationshipList } from '../_mockDataHelpers';

const createMockApplicationStatusData = (options) => {
  const { guid, relationshipList = [] } = options || {};
  const parsedGuids = typeof guid === 'string' && guid.includes('[') ? JSON.parse(guid) : guid;
  const guidsArray = Array.isArray(parsedGuids) ? parsedGuids : [parsedGuids];
  const mockRelationshipMap = guidsArray.reduce((acc, guidString) => {
    const mockRelationship = relationshipList.find(r => r.value === guidString) ||
    relationshipList[0];
    return { ...acc, [guidString]: mockRelationship };
  }, {});
  const staticRelationshipData = guidsArray.map(g => ({
    approvedTimestamp: getNow().toISOString(),
    submissionTimestamp: getNow().toISOString(),
    relationship: mockRelationshipMap[g] || relationshipList[0]
  }));
  const staticStatusData = crabAppStatusList.map(item => ({
    status: item.value,
    approvedTimestamp: getNow().toISOString(),
    submissionTimestamp: getNow().toISOString()
  }));
  const mockResponse = [
    ...staticRelationshipData,
    ...staticStatusData,
    ...Array.from({ length: 2 })].map((item, index) => {
    const mockMerchantsLength = getMockValue({ min: 1, max: 3 });
    const { relationshipCode, title } = (item && item.relationship) || relationshipList[0] || {};
    const mockStatus = (item && item.status) || getMockValue({ type: 'list', list: crabAppStatusList });
    const mockFromCrab = index % 2 === 0;
    const mockApp = {
      mids: mockStatus === 'approved' ? Array.from({ length: mockMerchantsLength }).map(() => getMockValue({ type: 'mid' })) : [],
      dbaNames: Array.from({ length: mockMerchantsLength }).map(() => getMockValue({ type: 'businessName' })),
      legalName: getMockValue({ type: 'businessName' }),
      applicationId: mockFromCrab ? `${getMockValue({ min: 1, max: 15 })}` : null,
      applicationBusinessNumber: mockFromCrab ? `${index + 1}` : null,
      fromCrab: mockFromCrab,
      status: mockStatus,
      relationshipCode,
      relationshipName: title,
      submissionTimestamp: mockStatus !== 'draft' ? getMockValue({ type: 'isoDate', min: 2020, max: 2020 }) : null,
      approvedTimestamp: mockStatus === 'approved' ? getMockValue({ type: 'isoDate', min: 2020, max: 2020 }) : null,
      maskedAccountNumber: getMockValue({ type: 'accountNumber', isMasked: true }),
      maskedRoutingNumber: getMockValue({ type: 'routingNumber', isMasked: true }),
      ...(item && item[index])
    };
    return mockApp;
  });
  return mockResponse;
};

export const mockApplicationStatusGet = (body, params, options) => {
  const { guid = '' } = options || {};
  return {
    [guid]: {
      applicationStatuses: [{
        header: [
          {
            order: 1,
            key: 'mids',
            englishName: 'MIDs'
          },
          {
            order: 2,
            key: 'dbaNames',
            englishName: 'DBA Names'
          },
          {
            order: 3,
            key: 'legalName',
            englishName: 'Legal Name'
          },
          {
            order: 4,
            key: 'relationshipCode',
            englishName: 'Relationship Code'
          },
          {
            order: 5,
            key: 'relationshipName',
            englishName: 'Relationship Name'
          },
          {
            order: 6,
            key: 'status',
            englishName: 'Status'
          },
          {
            order: 7,
            key: 'submissionTimestamp',
            englishName: 'Submission Timestamp'
          },
          {
            order: 8,
            key: 'approvedTimestamp',
            englishName: 'Approved Timestamp'
          },
          {
            order: 9,
            key: 'maskedAccountNumber',
            englishName: 'Masked Account Number'
          },
          {
            order: 10,
            key: 'maskedRoutingNumber',
            englishName: 'Masked Routing Number'
          },
          {
            order: 11,
            key: 'applicationBusinessNumber',
            englishName: 'Application Business ID'
          }
        ],
        data: createMockApplicationStatusData(options)
      }]
    }
  };
};

const mockRelationshipList = getMockRelationshipList();
const mockGuid = (`${mockRelationshipList.map(r => r.value)}`).toString();
export const mockApplicationStatusResponse = mockApplicationStatusGet({}, {}, {
  guid: mockGuid,
  relationshipList: mockRelationshipList
})[mockGuid];

export const mockApplicationStatusGetFormatted = transformData({
  data: { ...mockApplicationStatusResponse, pathname: '?today&0' },
  toSchema: 'frontend',
  template: applicationStatusTemplate,
  version: '1.0'
});

export default mockApplicationStatusGet;
