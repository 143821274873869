export const initialState = {
  email: ''
};

function forgotPassword (state = initialState, action) {
  switch (action.type) {
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.email
      };
    default:
      return state;
  }
}

export default forgotPassword;
