import { mockPrevetsGet, createMockPrevets } from '@f1/shared/src/data/crab/mockPrevets';
import { defaultMockUrl } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';
import {
  mockPreferredRelationships,
  mockGuidToRelationshipMap,
  mockElevatedRelationships,
  allMockRelationships
} from './mockPartnerCrabV1Application';

const staticApps = {
  0: { // App 00 - Repay/Preferred Risk - Waiting on employee (FT)
    meta: {
      prevetStatus: 'waiting_on_app_review',
      prevetName: 'App 00 - Repay/Preferred Risk - Waiting on employee (FT)',
      relationship: mockPreferredRelationships.funcTest,
      prevetSubmittedTimestamp: '2019-12-30T20:20:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:20:00Z'
    },
    filesList: [{ fileName: 'merchant_statement.pdf', url: defaultMockUrl, tags: [{ tagObject: 'merchant_statement' }, { tagObject: 'uploaded_by_partner' }] }]
  },
  1: { // App 01 - Netevia/Elevated Risk - Proceed
    meta: {
      prevetStatus: 'proceed',
      prevetName: 'App 01 - Netevia/Elevated Risk - Proceed',
      relationship: mockElevatedRelationships.netevia,
      prevetSubmittedTimestamp: '2019-12-30T20:21:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:21:00Z'
    },
    filesList: [{ fileName: 'merchant_statement.pdf', url: defaultMockUrl, tags: [{ tagObject: 'merchant_statement' }, { tagObject: 'uploaded_by_partner' }] }]
  },
  2: { // App 02 - Repay/Preferred Risk - Decline
    meta: {
      prevetStatus: 'decline',
      prevetName: 'App 02 - Repay/Preferred Risk - Decline',
      relationship: mockPreferredRelationships.funcTest,
      prevetSubmittedTimestamp: '2019-12-30T20:22:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:22:00Z'
    },
    filesList: [{ fileName: 'merchant_statement.pdf', url: defaultMockUrl, tags: [{ tagObject: 'merchant_statement' }, { tagObject: 'uploaded_by_partner' }] }]
  },
  3: { // App 03 - Repay/Preferred Risk - Abandoned
    meta: {
      prevetStatus: 'abandoned',
      prevetName: 'App 03 - Repay/Preferred Risk - Abandoned',
      relationship: mockElevatedRelationships.netevia,
      prevetSubmittedTimestamp: '2019-12-30T20:22:00Z',
      prevetCompletedTimestamp: '2019-12-31T20:22:00Z'
    },
    filesList: [{ fileName: 'merchant_statement.pdf', url: defaultMockUrl, tags: [{ tagObject: 'merchant_statement' }, { tagObject: 'uploaded_by_partner' }] }]
  }
};

// Create list of ALL mock applications ONCE so paged data doesn't get recreated multiple times
export const mockAllPrevets = createMockPrevets({
  staticApps,
  userType: 'partner',
  relationshipList: allMockRelationships,
  mockGuidToRelationshipMap
});

export const mockPartnerPrevetsGet = (body, params, options) => {
  const { guid } = options || {};
  const mockData = mockPrevetsGet(body, params, {
    ...options,
    userType: 'partner',
    mockAllPrevets,
    staticApps
  });
  return { [guid]: mockData };
};
