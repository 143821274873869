import { createMockFile } from '@f1/shared/src/data/sharedBoarding/mockBoardingApplicationFiles';

const mockPartnerCrabV1ToMpa = (body, params, options) => {
  const { guid = '' } = options || {};
  const staticFile = {
    fileName: 'mock-mpa-to-pdf',
    tags: [{ tagObject: 'uploaded_by_system' }],
    url: 'https://dummyimage.com/600x400/000/fff',
    guidType: 'applicationMpaId',
    guidValue: guid
  };
  return {
    [guid]: { mpaPdf: createMockFile(1, { ...options, staticFile }) }
  };
};

export default mockPartnerCrabV1ToMpa;
