import React from 'react';
import PropTypes from 'prop-types';
import { decamelize, capitalize, isEmpty } from '@f1/shared/src/_helpers';
import FooterLink from './FooterLink';
import { footerCSS } from './_styles';

class FooterSection extends React.PureComponent {
  buildColumns = () => {
    const { ftrLinks } = this.props;
    let colNum = 1;
    const columns = [];
    if (isEmpty(ftrLinks)) return columns;
    const arrayToMap = !isEmpty(ftrLinks?.submenu)
      ? ftrLinks.submenu
      : [...(!isEmpty(ftrLinks) ? [ftrLinks] : [])];
    arrayToMap.forEach((link, index) => {
      if (typeof columns[colNum] === 'undefined') columns.push([]);
      const cellNum = index + 1;
      const maxPerRow = 3;
      const colCheck = cellNum > (colNum * maxPerRow);
      if (colCheck) colNum += 1;
      columns[colNum - 1].push(link);
    });
    return columns;
  }

  render () {
    const {
      section,
      type
    } = this.props;
    const decamelWords = decamelize(section).replace(/_/g, ' ');
    const words = decamelWords.split(' ');
    return (
      <div className="section" style={footerCSS.section}>
        <div className="header" style={footerCSS.liHeader}>
          { words.map(word => capitalize(word)).join(' ') }
        </div>
        <div className="linksWrapper" style={footerCSS.ulWrapper}>
          {this.buildColumns().map((columnLinks, index) => (
            <ul key={`${index.toString()}`} className="links" style={footerCSS.ul}>
              {columnLinks.map(link => (
                <FooterLink
                  key={link.text}
                  link={link}
                  type={type}
                />
              ))}
            </ul>
          ))}
        </div>
      </div>
    );
  }
}

FooterSection.propTypes = {
  type: PropTypes.string,
  section: PropTypes.string,
  ftrLinks: PropTypes.oneOfType([PropTypes.object])
};

FooterSection.defaultProps = {
  type: 'sLink',
  section: '',
  ftrLinks: {}
};

export default FooterSection;
