import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  endpoint,
  isEmpty,
  capitalize,
  transformData
} from '@f1/shared/src/_helpers';
import {
  Spinner, DataTable, CustomApiErr
} from '@f1/shared';
import ChartWrapper from '@f1/shared/src/ChartWrapper';
import reportDataTemplate from '../../../data/report/templates/reportDataTemplate';
import { axiosRequest } from '../../../utils';

export default function PortfolioReport ({
  reportType, guidValue, isPartnerView
}) {
  const [reportData, setReportData] = useState([]);
  const [reportGraphData, setReportGraphData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    !isEmpty(guidValue.guid) && getData();
  }, [reportType, isPartnerView, guidValue]);

  const getData = async () => {
    setErr(null);
    setStatus(null);
    setSpinnerLoading(true);
    // Handle changing API call or guid types based on report type and if partner/merchant
    // Auth, Settlement, and Chargeback come from the dashboard/landing page call
    let dashboardEndpoint = isPartnerView
      ? `${endpoint.partnerPortal.landingPage}`
      : `${endpoint.merchant.dashboard}`;
    const requestGuid = isPartnerView
      ? { relationshipIdList: guidValue.guid }
      : { merchantGuid: guidValue.guid };
    if (reportType === 'refund') {
      dashboardEndpoint = isPartnerView
        ? endpoint.partnerPortal.refund
        : endpoint.merchant.refund;
    }
    // END Handle changing API call
    const apiRes = await axiosRequest({
      fullPageLoad: false,
      url: dashboardEndpoint,
      method: 'get',
      requestGuid
    });
    if (apiRes?.errorDetails instanceof Error) {
      setSpinnerLoading(false);
      setErr(apiRes.state.err);
      setStatus(apiRes.state.status);
    } else if (!isEmpty(apiRes.data)) {
      setSpinnerLoading(false);
      setErr(null);
      setStatus(apiRes.state.status);
      // Handle setting template to use for the type of report data being retrieved
      let toSchema;
      if (reportType === 'refund') {
        toSchema = 'toPortalRefundData';
      } else {
        // all other reports get data from the landing page api call
        toSchema = isPartnerView ? 'toPartnerLandingPageData' : 'toMerchantLandingPageData';
      }
      // END Handle setting template
      const formattedData = transformData({
        data: apiRes.data,
        toSchema,
        template: reportDataTemplate,
        version: '1.0'
      });
      // for sales reports, we seem to just be using the settlement data
      const report = (reportType === 'sales' && toSchema === 'toPartnerLandingPageData') ? 'settlement' : reportType;
      setReportData(formattedData[report]);
      setReportGraphData(formattedData[`${report}Chart`]);
    }
  };

  return (
    <div>
      <h2>
        {isPartnerView ? `Portfolio ${capitalize(reportType)} Report` : `Merchant ${capitalize(reportType)} Report`}
      </h2>
      <div
        id="dataTableWrapper"
        className={reportType}
        style={{ position: 'relative' }}
      >
        <Spinner loading={spinnerLoading} />
        {err || (isEmpty(reportData) && isEmpty(reportGraphData)) ? (
          <CustomApiErr loading={spinnerLoading} status={status} customErr={err} />
        ) : (
          <>
            <ChartWrapper
              id={`${reportType}Graph`}
              data={reportGraphData}
              axiosRequest={axiosRequest}
            />
            <hr />
            <DataTable
              fixedHeight
              passedData={reportData || []}
              dataGroup={reportType}
              downloadFile={{ fileName: `${capitalize(reportType)} Volume Report`, sheetName: `${capitalize(reportType)} Volume Report` }}
              hiddenColumns={['count']}
              headerStyle={{ minWidth: '10em' }}
              modal={false}
              defaultSortCol={isPartnerView ? 'date' : 'month'}
            />
          </>
        )}
      </div>
    </div>
  );
}

PortfolioReport.propTypes = {
  reportType: PropTypes.oneOf(['authorization', 'chargeback', 'settlement', 'sales', 'refund']),
  guidValue: PropTypes.oneOfType([PropTypes.object]),
  isPartnerView: PropTypes.bool
};

PortfolioReport.defaultProps = {
  reportType: 'authorization',
  guidValue: {},
  isPartnerView: false
};
