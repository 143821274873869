import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockPartnerMonthSummaryReportRes = (body, params, options) => {
  const { guid = 'any' } = options;
  return {
    [guid]: {
      summaryData: [
        {
          merchantGuid: getMockValue({ type: 'guid' }),
          mid: getMockValue({ type: 'mid' }),
          dbaName: getMockValue({ type: 'businessName' }),
          legalName: getMockValue({ type: 'businessName' }),
          dateApproved: getMockValue({ type: 'date' }),
          dateClosed: getMockValue({ type: 'date' }),
          week1: {
            salesVolume: getMockValue({ type: 'number' }),
            salesCount: getMockValue({ type: 'number' }),
            refundVolume: getMockValue({ type: 'number' }),
            refundCount: getMockValue({ type: 'number' }),
            chargebackVolume: getMockValue({ type: 'number' }),
            chargebackCount: getMockValue({ type: 'number' }),
            chargebackRatio: getMockValue({ type: 'ratio' })
          },
          week2: {
            salesVolume: getMockValue({ type: 'number' }),
            salesCount: getMockValue({ type: 'number' }),
            refundVolume: getMockValue({ type: 'number' }),
            refundCount: getMockValue({ type: 'number' }),
            chargebackVolume: getMockValue({ type: 'number' }),
            chargebackCount: getMockValue({ type: 'number' }),
            chargebackRatio: getMockValue({ type: 'ratio' })
          },
          week3: {
            salesVolume: getMockValue({ type: 'number' }),
            salesCount: getMockValue({ type: 'number' }),
            refundVolume: getMockValue({ type: 'number' }),
            refundCount: getMockValue({ type: 'number' }),
            chargebackVolume: getMockValue({ type: 'number' }),
            chargebackCount: getMockValue({ type: 'number' }),
            chargebackRatio: getMockValue({ type: 'ratio' })
          },
          week4: {
            salesVolume: getMockValue({ type: 'number' }),
            salesCount: getMockValue({ type: 'number' }),
            refundVolume: getMockValue({ type: 'number' }),
            refundCount: getMockValue({ type: 'number' }),
            chargebackVolume: getMockValue({ type: 'number' }),
            chargebackCount: getMockValue({ type: 'number' }),
            chargebackRatio: getMockValue({ type: 'ratio' })
          },
          week5: {
            salesVolume: getMockValue({ type: 'number' }),
            salesCount: getMockValue({ type: 'number' }),
            refundVolume: getMockValue({ type: 'number' }),
            refundCount: getMockValue({ type: 'number' }),
            chargebackVolume: getMockValue({ type: 'number' }),
            chargebackCount: getMockValue({ type: 'number' }),
            chargebackRatio: getMockValue({ type: 'ratio' })
          }
        }
      ],
      pagingInfo: {
        totalNumberOfRecords: 1,
        pageIndex: 0,
        pageSize: 1
      }
    }
  };
};

export default mockPartnerMonthSummaryReportRes;
