import { toBackendValue } from '@f1/shared/src/_templateHelpers';
import { delegateEditFormFields, partnerDelegatePermissions } from '../delegateFormFields';

export const delegateTemplateV2 = {
  frontend: (schema, version) => {
    const { relationships } = schema || {};
    if (version === '1.0') {
      return Array.isArray(relationships) && relationships.map((relationship) => {
        const uniqueKey = `${relationship?.relationshipId}_${relationship?.email}`;
        return ({
          edit: relationship?.relationshipId,
          deleteDelegate: {
            relationshipId: relationship?.relationshipId,
            email: relationship.email
          },
          select: uniqueKey,
          email: relationship.email,
          relationshipName: relationship.relationshipName,
          relationshipId: relationship.relationshipId,
          uniqueKey,
          ...getPartnerDelegateAccessFrontend(relationship)
        });
      });
    }
    return relationships;
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const access = schema?.permissions && Array.isArray(schema?.permissions)
        ? Object.assign({}, ...schema?.permissions)
        : schema?.permissions;
      return {
        email: schema.email,
        relationshipAccess: getPartnerDelegateAccessBackend(access)
      };
    }
    return schema;
  }
};

const getPartnerDelegateAccessFrontend = backendData => (
  partnerDelegatePermissions.reduce((acc, item) => ({
    ...acc,
    [item.value]: backendData?.[item.value]
  }), {})
);

const getPartnerDelegateAccessBackend = frontendData => (
  partnerDelegatePermissions.reduce((acc, item) => ({
    ...acc,
    [item.value]: toBackendValue(
      frontendData?.[item.value] || false,
      delegateEditFormFields[0].props
    )
  }), {})
);

export default delegateTemplateV2;
