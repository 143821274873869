import { icons } from '@f1/shared/images/_icons';
import { envIsDevOrLess } from '@f1/shared/src/_helpers';

export const allLinks = {
  reports: { text: 'Reports', href: '/achReport', category: 'portal' },
  reportAuth: { text: 'Authorization', href: '/reports/authorization', category: 'portal' },
  reportSales: { text: 'Sales', href: '/reports/sales', category: 'portal' },
  reportChargeback: { text: 'Chargeback', href: '/reports/chargeback', category: 'portal' },
  reportRefund: { text: 'Refund', href: '/reports/refund', category: 'portal' },
  reportReserve: { text: 'Reserve', href: '/reports/reserve', category: 'portal' },
  reportTransactionProfitability: { text: 'Transaction Profitability', href: '/reports/transactionProfitability', category: 'portal' },
  portfolioBatchReport: { text: 'Portfolio Batch Report', href: '/reports/portfolioBatchReport', category: 'portal' },
  batchDetails: { text: 'Batch Details', href: '/batchDetails', category: 'portal' },
  actionSuggested: { text: 'Action Suggested', href: '/actionSuggested', category: 'portal' },
  achReport: { text: 'ACH Report', href: '/achReport', category: 'portal' },
  applicationStatus: { text: 'Application Status', href: '/applicationStatus', category: 'portal' },
  dashboard: { text: 'Dashboard', href: '/dashboard', category: 'portal' },
  faq: { text: 'FAQ', href: '/faq', category: 'portal' },
  applicationV2: { text: 'Merchant Application', href: '/application-v2', category: 'portal' },
  prevet: { text: 'Prevet', href: '/prevet', category: 'portal' },
  account: { text: 'Account', href: '/account', category: 'portal' },
  documents: { text: 'Documents', href: '/documents', category: 'portal' },
  support: { text: 'Tickets/Support', href: '/tickets', category: 'portal' },
  signOut: { text: 'Sign Out', href: 'signOut' },
  merchantDetails: { text: 'Merchant Details', href: '/merchantDetails', category: 'portal' },
  notices: { text: 'Notices', href: '/notices', category: 'portal' },
  merchantLedger: { text: 'Merchant Ledger', href: '/report/merchantLedger', category: 'portal' },
  merchantsOnHold: { text: 'Merchants On Hold', href: '/report/merchantsOnHold', category: 'portal' },
  monthlySummary: { text: 'Monthly Summary', href: '/report/monthlySummary', category: 'portal' },
  myReports: { text: 'My Reports', href: '/report/myreports', category: 'portal' },
  notifications: { text: 'Notifications', icon: icons.notificationsNone.default, category: 'portal' },
  training: { text: 'Training', href: '/training', category: 'portal' }
};
export const siteLinks = (permissions, isPartner = true) => ({
  // Portal links
  reports: {
    ...allLinks.reports,
    submenu: [
      { ...allLinks.achReport },
      ...(!isPartner ? [{ ...allLinks.merchantDetails }] : []),
      ...(isPartner ? [{ ...allLinks.actionSuggested }] : []),
      ...(isPartner ? [{ ...allLinks.applicationStatus }] : []),
      ...(isPartner ? [{ ...allLinks.reportAuth }] : []),
      ...(isPartner ? [{ ...allLinks.reportChargeback }] : []),
      // TODO SNEK-2049: remove env check
      ...(envIsDevOrLess() && isPartner ? [{ ...allLinks.merchantLedger }] : []),
      { ...allLinks.merchantsOnHold },
      ...(isPartner ? [{ ...allLinks.monthlySummary }] : []),
      { ...allLinks.myReports },
      ...(isPartner ? [{ ...allLinks.notices }] : []),
      { ...allLinks.portfolioBatchReport },
      ...(isPartner ? [{ ...allLinks.reportRefund }] : []),
      ...(isPartner ? [{ ...allLinks.reportReserve }] : []),
      ...(isPartner ? [{ ...allLinks.reportSales }] : []),
      // TODO: BIRB-7791 - remove envIsNotProd
      { ...allLinks.reportTransactionProfitability }
    ]
  },
  merchants: {
    ...allLinks.merchantDetails,
    text: 'Merchants'
  },
  applications: {
    ...allLinks.applicationV2,
    text: 'Applications',
    submenu: [
      { ...allLinks.applicationV2 },
      { ...allLinks.prevet }
    ]
  },
  tickets: {
    ...allLinks.support,
    text: 'Tickets'
  },
  dashboard: { ...allLinks.dashboard },
  faq: { ...allLinks.faq },
  notifications: { ...allLinks.notifications },
  account: {
    ...allLinks.account,
    submenu: [
      { ...allLinks.account, category: null },
      ...(permissions?.hasDocumentAccess ? [{ ...allLinks.documents }] : []),
      { ...allLinks.faq },
      { ...allLinks.support },
      { ...allLinks.training },
      { ...allLinks.signOut }
    ]
  }
});

export const mockGetHeaderLinks = () => {
  const mockLinks = siteLinks({ hasDashboardAccess: true });
  return ([
    { ...mockLinks.dashboard, customSubmenu: null },
    { ...mockLinks.notifications, customSubmenu: null },
    { ...mockLinks.account, customSubmenu: null }
  ]);
};

export default siteLinks;
