import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, isPublicUrl } from '@f1/shared/src/_helpers';
import { footerCSS } from '@f1/shared/src/_styles';
import * as actionCreators from '../../redux/actions/actionCreators';
import { getLinks } from '../../utils';
import FooterSection from './FooterSection';
import FooterLegal from './FooterLegal';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    user: state.authenticate.user,
    boardingEnabled: state.appState.boardingEnabled,
    viewAsMerchant: state.filterData.activeFilters.viewAsMerchant
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundFooter extends React.PureComponent {
  constructor (props) {
    super(props);
    this.mounted = false;
    this.state = {
      links: []
    };
  }

  componentDidMount () {
    this.mounted = true;
    this.updateLinks();
  }

  componentDidUpdate = (prevProps) => {
    const {
      isAuthenticated,
      location,
      user: { isPartner },
      boardingEnabled,
      viewAsMerchant
    } = this.props;
    if ((prevProps.isAuthenticated !== isAuthenticated) ||
    (prevProps.location.pathname !== location.pathname) ||
    (isPartner && prevProps.boardingEnabled !== boardingEnabled && boardingEnabled) ||
    (prevProps.viewAsMerchant !== viewAsMerchant)) {
      this.updateLinks();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  updateLinks = async () => {
    const { viewAsMerchant } = this.props;
    const links = await getLinks('footer', viewAsMerchant);
    this.updateState({ links });
  }

  updateState = (state) => {
    this.mounted && this.setState(state);
  }

  render () {
    const { links } = this.state;
    const { isAuthenticated } = this.props;
    return isPublicUrl ? null : (
      <footer className="footer" style={footerCSS.wrap}>
        {/*
          If we update the footer to show links, we will need to support updates
          for the DataTable maxHeight calculation, and dynamic footer heights
          ie, var(--height-footer)
        */}
        { !isEmpty(links) && (
          <div className="sections" style={footerCSS.innerWrap}>
            {(isAuthenticated) && (
              links.map((link, index) => <FooterSection key={link.text} section={link.text} type="pLink" ftrLinks={link || {}} />)
            )}
          </div>
        )}
        <FooterLegal />
      </footer>
    );
  }
}

BoundFooter.propTypes = {
  isAuthenticated: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  user: PropTypes.shape({
    isPartner: PropTypes.bool
  }),
  boardingEnabled: PropTypes.bool,
  viewAsMerchant: PropTypes.bool
};

BoundFooter.defaultProps = {
  isAuthenticated: false,
  location: {
    pathname: '/'
  },
  user: {
    isPartner: false
  },
  boardingEnabled: false,
  viewAsMerchant: false
};
const Footer = connect(mapStateToProps, mapDispatchToProps)(BoundFooter);

export default Footer;
