import { setPastMonth, getThisYearMonth } from '@f1/shared/src/_helpers';

export const merchantDataDefaultState = {
  dashboard: {
    salesVolume: [],
    chargebacks: [],
    dispute: [],
    authorizations: {}
  },
  refunds: {},
  reserves: {},
  dispute: {},
  avsUsage: {},
  timeUsage: {}
};

export const partnerDataDefaultState = {
  allRelationships: [],
  dashboard: {
    portfolioHealth: 0,
    volumeCount: {
      authorization: [],
      settlement: [],
      chargeback: [],
      dispute: []
    },
    top5LeastProducingMerchants: [],
    top5MostProducingMerchants: [],
    top5UnhealthyPartners: [],
    top5HealthyPartners: [],
    top5LeastProducingPartners: [],
    top5MostProducingPartners: [],
    chargebackPortfolioHealth: {
      healthyCount: 0,
      healthyMerchants: [],
      inDangerCount: 0,
      inDangerMerchants: [],
      onProgramCount: 0,
      onProgramMerchants: [],
      unhealthyCount: 0,
      unhealthyMerchants: []
    },
    partnerMonthlyPerformance: [],
    level: 1,
    downlineList: []
  }
};

export const initialState = {
  merchantData: {
    dashboard: { ...merchantDataDefaultState.dashboard },
    refunds: {}, // refers to refund databox
    reserves: {},
    currentReport: '',
    reportDataFetched: {
      chargeback: false,
      dispute: false,
      sales: false,
      authorization: false
    },
    miniReport: {
      authorization: {},
      sales: {},
      chargeback: {}
    },
    timeUsage: {}
  },
  partnerData: {
    allRelationships: [],
    dashboard: { ...partnerDataDefaultState.dashboard },
    refunds: {}, // refers to the refund databox
    applications: [],
    reserves: {},
    downlineList: []
  },
  varListPolling: {
    pollingOn: false
  },
  delegates: {
    relationshipIdToDelegateInfo: [],
    merchantGuidToDelegateInfo: []
  },
  dataRange: {
    dataFrom: setPastMonth({ pastMonth: true, months: 6 }).dateFrom,
    dataTo: setPastMonth({ pastMonth: true, months: 6 }).dateTo
  },
  yearMonth: {
    yearMonthFrom: getThisYearMonth({ lastMonth: true }),
    yearMonthTo: getThisYearMonth({ lastMonth: true })
  }
};

function dataUpdate (state = initialState, actions) {
  switch (actions.type) {
    case 'DATA_UPDATE':
      return {
        ...state,
        ...(actions.merchantData && {
          merchantData: { ...state.merchantData, ...actions.merchantData }
        }),
        ...(actions.partnerData && {
          partnerData: {
            ...state.partnerData,
            ...actions.partnerData
          }
        }),
        ...(actions.delegates && {
          delegates: {
            ...state.delegates,
            ...actions.delegates.relationshipIdToDelegateInfo && {
              relationshipIdToDelegateInfo: actions.delegates.relationshipIdToDelegateInfo
            },
            ...actions.delegates.merchantGuidToDelegateInfo && {
              merchantGuidToDelegateInfo: actions.delegates.merchantGuidToDelegateInfo
            }
          }
        }),
        ...(actions.dataRange && {
          dataRange: {
            ...state.dataRange,
            ...actions.dataRange
          }
        }),
        ...(actions.yearMonth && {
          yearMonth: {
            ...state.yearMonth,
            ...actions.yearMonth
          }
        }),
        ...(actions.varListPolling && {
          varListPolling: {
            ...state.varListPolling,
            ...actions.varListPolling
          }
        })
      };
    default:
      return state;
  }
}

export default dataUpdate;
