import React from 'react';
import PropTypes from 'prop-types';
import {
  Navigate,
  Outlet,
  useLocation
} from 'react-router-dom';

export default function PrivateRoute ({
  store,
  redirectPath,
  checkActiveGuid,
  portal
}) {
  const { serverMaintenance } = store.getState().appState;
  if (serverMaintenance) {
    /* istanbul ignore next */
    return (
      <Navigate to="/serverMaintenance" replace />
    );
  }
  const location = useLocation();
  const { isAuthenticated, user } = store.getState().authenticate;
  if (isAuthenticated) {
    // PORTAL Specific
    portal && checkActiveGuid();
    if (portal && !user.isPartner && location.pathname.includes('/application')) {
      // prevent merchants from accessing /application
      return <Navigate exact to="/dashboard" />;
    }
    // END PORTAL Specific
    return <Outlet />;
  }
  return <Navigate to={redirectPath} state={{ from: location }} replace />;
}

PrivateRoute.propTypes = {
  store: PropTypes.oneOfType([PropTypes.any]),
  redirectPath: PropTypes.string,
  portal: PropTypes.bool,
  checkActiveGuid: PropTypes.func
};

PrivateRoute.defaultProps = {
  store: false,
  redirectPath: '/signin',
  portal: false,
  checkActiveGuid: () => {}
};
