import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { notificationFields } from '../formAssistantHelpers/notificationFields';

export const mockNotificationConfigGet = (body, params, options) => ({
  actionRequiredReport,
  crabNotification
});

const actionRequiredReport = {
  reportsActive: getMockValue({ type: 'boolean' }),
  reportFrequency: getMockValue({ type: 'list', list: notificationFields.actionRequiredReport.reportFrequency.list }),
  noStarts: getMockValue({ type: 'boolean' }),
  justTestProcessing: getMockValue({ type: 'boolean' }),
  stoppedProcessing: getMockValue({ type: 'boolean' }),
  terminated: getMockValue({ type: 'boolean' }),
  runningHot: getMockValue({ type: 'boolean' }),
  chargebackHealth: getMockValue({ type: 'boolean' }),
  achReject: true, // used for FT
  lastReportSendDate: getMockValue({ type: 'date' })
};

const crabNotification = {
  reportsActive: getMockValue({ type: 'boolean' }),
  approval: true, // used for FT
  withdrawn: getMockValue({ type: 'boolean' }),
  decline: getMockValue({ type: 'boolean' }),
  pendsPublication: getMockValue({ type: 'boolean' }),
  initialSubmission: getMockValue({ type: 'boolean' }),
  lastReportSendTimestamp: getMockValue({ type: 'isoDate' })
};

export default mockNotificationConfigGet;
