import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';

export const mockMerchantAvsUsageRes = (body, params, options) => {
  const { guid = 'testGuid' } = options;
  const numberProps = { min: 1 };
  return {
    [guid]: {
      header: {
        label: 'label',
        biaxial: [],
        lines: []
      },
      data: [
        { label: 'Feb', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'Mar', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'Apr', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'May', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'Jun', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'Jul', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) },
        { label: 'Aug', avs_used: getMockValue(numberProps), avs_not_used: getMockValue(numberProps) }
      ]
    }
  };
};

export default mockMerchantAvsUsageRes;
