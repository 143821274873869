import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { getPastMonths } from '@f1/shared/src/_helpers';
import { filterByYearMonth } from '@f1/shared/src/_testHelpers';

const createMockRefundTableData = (options) => {
  const { length } = options || {};
  const mockReundData = Array.from({ length: length || 26 }).map(() => ({
    txn: {
      purchaseAmount: getMockValue({ type: 'price' }),
      authorizationCodeSt: getMockValue({ type: 'list', list: ['00', '01', '02', '03'] }),
      authorizationAmount: getMockValue({ type: 'price' }),
      transactionDate: getMockValue({ type: 'date' }),
      status: 'Paid',
      achDate: getMockValue({ type: 'date' }),
      achTimestamp: getMockValue({ type: 'isoDate' }),
      associationDate: getMockValue({ type: 'date' }),
      transactionType: 'return',
      cardEntryMethod: 'E-Commerce',
      avsResponseCode: getMockValue({ type: 'list', list: ['04', '05', '06', '07'] }),
      batchIdFt: getMockValue({ min: 10000000, max: 99999999, stringify: true }),
      card: {
        cardNumber: {
          cardNumberId: getMockValue({ type: 'guid' }),
          cardNumber: getMockValue({ type: 'cardNumber' })
        },
        cardBrand: getMockValue({ type: 'cardBrand' })
      },
      merchant: {
        mid: getMockValue({ type: 'mid' }),
        legalName: getMockValue({ type: 'businessName' })
      }
    }
  }));
  return mockReundData;
};

export const mockReportRefundGet = (body, params, options) => {
  const { guid = '' } = options || {};
  const months = getPastMonths();
  const data = Array.from({ length: 12 })
    .map((x, index) => ({
      month: months[(months.length - 1) - index].value,
      count: getMockValue(),
      volume: getMockValue()
    }));
  return {
    [guid]: {
      refundBarGraph: {
        header: {
          label: 'month',
          lines: [],
          biaxial: ['count'],
          currency: true,
          biaxialCurrency: null
        },
        data: filterByYearMonth(data, { ...options, ...params })
      },
      refundTransactionTable: {
        header: [
          { order: 1, key: 'merchant.mid', englishName: 'MID' },
          { order: 2, key: 'merchant.legalName', englishName: 'Legal Name' },
          { order: 3, key: 'batchIdFt', englishName: 'Batch ID' },
          { order: 4, key: 'purchaseAmount', englishName: 'Purchase Amount' },
          { order: 5, key: 'transactionDate', englishName: 'Transaction Date' },
          { order: 6, key: 'authorizationCodeSt', englishName: 'Authorization Code' },
          { order: 7, key: 'authorizationAmount', englishName: 'Authorization Amount' },
          { order: 8, key: 'status', englishName: 'Status' },
          { order: 9, key: 'achDate', englishName: 'ACH Date' },
          { order: 10, key: 'achTimestamp', englishName: 'ACH Timestamp' },
          { order: 11, key: 'associationDate', englishName: 'Association Date' },
          { order: 12, key: 'card.cardBrand', englishName: 'Card Brand' },
          { order: 13, key: 'card.cardNumber.cardNumber', englishName: 'Card Number' },
          { order: 14, key: 'transactionType', englishName: 'Transaction Type' },
          { order: 15, key: 'cardEntryMethod', englishName: 'Card Entry Method' },
          { order: 16, key: 'avsResponseCode', englishName: 'AVS Response Code' }
        ],
        data: createMockRefundTableData()
      }
    }
  };
};

export default mockReportRefundGet;
