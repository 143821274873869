import React, { lazy } from 'react';
import {
  buildDataTable,
  formatDateForFEView,
  getNow,
  getThisYearMonth,
  isEmpty,
  snakeToTitle,
  stringFromDateObj,
  toCamelCase
} from '@f1/shared/src/_helpers';
import { icons } from '@f1/shared/images/_icons';

const ToolTip = lazy(() => import('@f1/shared/').then(module => ({ default: module.ToolTip })));

const rollupHeaders = {
  columnOrder: ['status', 'today', 'mtd', 'lastMonth', 'ytd', 'all'],
  headerKeyMap: {
    status: 'Status',
    today: 'Today',
    mtd: 'MTD',
    lastMonth: 'Last Month',
    ytd: 'YTD',
    all: 'All'
  }
};
const humanReadable = {
  draft: 'Draft',
  waitingOnInitialSignature: 'Waiting On Initial Signature',
  waitingOnOps: 'Waiting On OPS',
  waitingOnPartner: 'Waiting On Partner',
  waitingOnCredit: 'Waiting On Credit',
  waitingOnAppReview: 'Waiting On App Review',
  waitingOnReview: 'Waiting On App Review',
  waitingOnSignaturePostPends: 'Waiting On Signature Post Pends',
  waitingOnBank: 'Waiting On Bank',
  waitingOnBankAllotment: 'Waiting On Bank Allotment',
  pendedByBank: 'Pended By Bank',
  boarding: 'Boarding',
  approved: 'Approved',
  approvedWaitingOnBank: 'Approved (Waiting On Bank)',
  withdrawn: 'Withdrawn',
  declined: 'Declined',
  abandoned: 'Abandoned',
  apiBoardedToProcessor: 'Boarded to Processor'
};

const applicationStatusTemplate = {
  frontend: (schema, version) => {
    const { applicationStatuses, clickCallback } = schema;
    const hiddenColumns = ['fromCrab', 'statusCode', 'submittedToday', 'approvedToday', 'submittedMtd', 'approvedMtd', 'submittedLastMonth', 'approvedLastMonth', 'submittedYtd', 'approvedYtd'];
    if (version === '1.0' && !isEmpty(schema)) {
      const header = applicationStatuses?.[0]?.header;
      const cleanedMidsData = applicationStatuses?.[0]?.data.map(item => ({
        ...item,
        // eslint-disable-next-line react/jsx-one-expression-per-line
        mids: (isEmpty(item?.mids) || item?.mids?.[0] === null) ? '-' : <div>{ item?.mids?.map(aMid => (<span key={aMid}>{formatAnchorTag(aMid, '/merchantDetails?mid=')}<br /></span>)) }</div>,
        dbaNames: (isEmpty(item?.dbaNames) || item?.dbaNames?.[0] === null) ? '-' : item.dbaNames.join(', '),
        legalName: item?.legalName,
        // eslint-disable-next-line react/jsx-one-expression-per-line
        applicationBusinessNumber: item?.fromCrab ? formatAnchorTag(item?.applicationId, '/application-v2?id=', item?.applicationBusinessNumber) : <ToolTip infoTip infoTipDisplay={{ ...icons.centered, height: '13px', width: '13px' }}><span>Only available when the boarding tool is used</span></ToolTip>
      }));
      const standardData =
        buildDataTable(header, formatDataArrayForRollup(cleanedMidsData));
      const rolledUpData = applicationStatusRollupTemplate.rollup(standardData.data, clickCallback);
      const relationshipData = applicationStatusRollupTemplate.byRelationship(
        standardData.data, clickCallback
      );
      return {
        ...standardData,
        ...rolledUpData,
        ...relationshipData,
        hiddenColumns
      };
    }
    return schema || { header: [], data: [], hiddenColumns };
  }
};

export const applicationStatusRollupTemplate = {
  rollup: (data, callback) => {
    const newStatuses = {};
    (data || []).forEach((item) => {
      const incrementAndAddCallback = (col, status) => {
        const value = newStatuses[status][col] === 0 ? 1 : newStatuses[status][col].value + 1;
        return {
          action: callback, value, type: 'status', frequency: col, status, tab: 'all'
        };
      };
      const status = item?.statusCode || 'unknown';
      if (status !== 'unknown') {
        if (!Object.prototype.hasOwnProperty.call(newStatuses, status)) {
        // if it doesn't already exist, init a new property
          newStatuses[status] = {
            status: humanReadable[status] || status, // if not in the list, just return raw status
            today: 0,
            mtd: 0,
            lastMonth: 0,
            ytd: 0,
            all: 0
          };
        }
        if (status === 'approved') {
          if (item?.approvedToday) { newStatuses[status].today = incrementAndAddCallback('today', status); }
          if (item?.approvedMtd) { newStatuses[status].mtd = incrementAndAddCallback('mtd', status); }
          if (item?.approvedLastMonth) { newStatuses[status].lastMonth = incrementAndAddCallback('lastMonth', status); }
          if (item?.approvedYtd) { newStatuses[status].ytd = incrementAndAddCallback('ytd', status); }
        } else {
          if (item?.submittedToday) { newStatuses[status].today = incrementAndAddCallback('today', status); }
          if (item?.submittedMtd) { newStatuses[status].mtd = incrementAndAddCallback('mtd', status); }
          if (item?.submittedLastMonth) { newStatuses[status].lastMonth = incrementAndAddCallback('lastMonth', status); }
          if (item?.submittedYtd) { newStatuses[status].ytd = incrementAndAddCallback('ytd', status); }
        }
        newStatuses[status].all = incrementAndAddCallback('all', status);
      }
    });
    // manually remove waitingOnCredit, waitingOnOps
    newStatuses.waitingOnCredit && delete newStatuses.waitingOnCredit;
    newStatuses.waitingOnOps && delete newStatuses.waitingOnOps;
    const rollupData = Object.values(newStatuses);
    return { rollupHeaders, rollupData };
  },
  byRelationship: (data, callback) => {
    const byRelationshipHeaders = {
      columnOrder: ['relationship', 'today', 'mtd', 'lastMonth', 'ytd', 'all'],
      headerKeyMap: {
        relationship: 'Relationship',
        today: 'Today',
        mtd: 'MTD',
        lastMonth: 'Last Month',
        ytd: 'YTD',
        all: 'All'
      }
    };
    const relationshipList = (data || []).map(entry => (entry?.relationshipName || 'unknown'));
    const uniqueRelationships = [...new Set(relationshipList)];
    const appsByRelationshipApproved = {};
    const appsByRelationshipSubmitted = {};
    uniqueRelationships.forEach((relationship) => {
      appsByRelationshipApproved[relationship] = buildByRelationshipMap(relationship);
      appsByRelationshipSubmitted[relationship] = buildByRelationshipMap(relationship);
    });

    (data || []).forEach((item) => {
      const status = item?.statusCode || 'unknown';
      const relationship = item?.relationshipName || 'unknown';
      if (status === 'approved') {
        if (item?.approvedToday) {
          appsByRelationshipApproved[relationship].today += 1;
        }
        if (item?.approvedMtd) {
          appsByRelationshipApproved[relationship].mtd += 1;
        }
        if (item?.approvedLastMonth) {
          appsByRelationshipApproved[relationship].lastMonth += 1;
        }
        if (item?.approvedYtd) {
          appsByRelationshipApproved[relationship].ytd += 1;
        }
        appsByRelationshipApproved[relationship].all += 1;
      }
      if (status !== 'draft') {
        if (item?.submittedToday) {
          appsByRelationshipSubmitted[relationship].today += 1;
        }
        if (item?.submittedMtd) {
          appsByRelationshipSubmitted[relationship].mtd += 1;
        }
        if (item?.submittedLastMonth) {
          appsByRelationshipSubmitted[relationship].lastMonth += 1;
        }
        if (item?.submittedYtd) {
          appsByRelationshipSubmitted[relationship].ytd += 1;
        }
        appsByRelationshipSubmitted[relationship].all += 1;
      }
    });

    const byApprovedRelationshipData = formatToCellClick(appsByRelationshipApproved, 'approved', callback);
    const bySubmittedRelationshipData = formatToCellClick(appsByRelationshipSubmitted, 'submitted', callback);
    return {
      byRelationshipHeaders,
      byRelationshipData: {
        approved: byApprovedRelationshipData,
        submitted: bySubmittedRelationshipData
      }
    };
  }
};

const formatToCellClick = (data, type, callback) => Object.values(data).map(
  entry => ({
    relationship: entry.relationship,
    today: entry.today > 0 ? {
      action: callback, value: entry.today, type, frequency: 'today', status: type, tab: type, relationship: entry.relationship
    } : entry.today,
    mtd: entry.mtd > 0 ? {
      action: callback, value: entry.mtd, type, frequency: 'mtd', status: type, tab: type, relationship: entry.relationship
    } : entry.mtd,
    lastMonth: entry.lastMonth > 0 ? {
      action: callback, value: entry.lastMonth, type, frequency: 'lastMonth', status: type, tab: type, relationship: entry.relationship
    } : entry.lastMonth,
    ytd: entry.ytd > 0 ? {
      action: callback, value: entry.ytd, type, frequency: 'ytd', status: type, tab: type, relationship: entry.relationship
    } : entry.ytd,
    all: entry.all > 0 ? {
      action: callback, value: entry.all, type, frequency: 'all', status: type, tab: type, relationship: entry.relationship
    } : entry.all
  })
);

export const applicationStatusCannedReports = {
  todaysApps: (data) => {
    const todaysAppsHeaders = [
      { order: 0, key: 'dbaName', englishName: 'DBA Name' },
      { order: 1, key: 'relationshipName', englishName: 'Relationship Name' },
      { order: 2, key: 'status', englishName: 'Status' },
      { order: 3, key: 'submissionTimestamp', englishName: 'Submission Timestamp' },
      { order: 4, key: 'approvedTimestamp', englishName: 'Approved Timestamp' }
    ];
    const filteredData = [];
    (data || []).forEach((item) => {
      const status = item?.statusCode || 'unknown';
      if (status === 'approved') {
        if (item?.approvedToday) {
          filteredData.push(item);
        }
      } else if (item?.submittedToday) {
        filteredData.push(item);
      }
    });
    return buildDataTable(todaysAppsHeaders, filteredData);
  },
  todaysAppsByRelationship: (data) => {
    const todaysAppsHeaders = [
      { order: 0, key: 'relationshipName', englishName: 'Relationship Name' },
      { order: 1, key: 'dbaName', englishName: 'DBA Name' },
      { order: 2, key: 'relationshipCode', englishName: 'Relationship Code' },
      { order: 3, key: 'status', englishName: 'Status' },
      { order: 4, key: 'submissionTimestamp', englishName: 'Submission Timestamp' },
      { order: 5, key: 'approvedTimestamp', englishName: 'Approved Timestamp' }
    ];
    const filteredData = [];
    (data || []).forEach((item) => {
      const status = item?.statusCode || 'unknown';
      if (status === 'approved') {
        if (item?.approvedToday) {
          filteredData.push(item);
        }
      } else if (item?.submittedToday) {
        filteredData.push(item);
      }
    });
    return buildDataTable(todaysAppsHeaders, filteredData);
  }
};

const buildByRelationshipMap = relationship => ({
  relationship,
  today: 0,
  mtd: 0,
  lastMonth: 0,
  ytd: 0,
  all: 0
});

const today = stringFromDateObj(getNow());
const thisMonth = getThisYearMonth();
const lastMonth = getThisYearMonth(true);
const thisYear = thisMonth.split('-')[0];

const formatDataArrayForRollup = data => data?.map((item) => {
  const submitted = stringFromDateObj(new Date(item?.submissionTimestamp));
  const submittedYearMonth = submitted.slice(0, 7);
  const submittedYear = submitted.split('-')[0];
  const approved = item?.approvedTimestamp ? stringFromDateObj(new Date(item?.approvedTimestamp)) : '';
  const approvedYearMonth = !isEmpty(approved) ? approved.slice(0, 7) : '';
  const approvedYear = !isEmpty(approved) ? approved.split('-')[0] : '';

  return {
    ...item,
    status: snakeToTitle(item?.status),
    statusCode: toCamelCase(item?.status, '_'),
    submissionTimestamp: item?.submissionTimestamp
      ? formatDateForFEView(item?.submissionTimestamp, { includeTime: true }) : '-',
    approvedTimestamp: item?.approvedTimestamp
      ? formatDateForFEView(item?.approvedTimestamp, { includeTime: true }) : '-',
    submittedToday: submitted === today,
    approvedToday: approved === today,
    submittedMtd: submittedYearMonth === thisMonth,
    approvedMtd: approvedYearMonth === thisMonth,
    submittedLastMonth: submittedYearMonth === lastMonth,
    approvedLastMonth: approvedYearMonth === lastMonth,
    submittedYtd: submittedYear === thisYear,
    approvedYtd: approvedYear === thisYear
  };
});

const formatAnchorTag = (value, endpoint, displayValue = value) => (<a href={`${endpoint}${value}`}>{displayValue}</a>);

export default applicationStatusTemplate;
