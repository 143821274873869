export const initialState = {
  pages: {
    signup: {
      valid: false,
      code: ''
    },
    signin: {
      valid: false,
      code: ''
    },
    forgot: {
      valid: false,
      code: ''
    },
    contact: {
      valid: false,
      code: ''
    }
  }
};

function captcha (state = initialState, actions) {
  switch (actions.type) {
    case 'SET_CAPTCHA':
      return {
        ...state,
        pages: {
          ...state.pages,
          [`${actions.path}`]: {
            ...state.pages[`${actions.path}`],
            code: actions.code,
            valid: actions.bool
          }
        }
      };
    default:
      return state;
  }
}

export default captcha;
