import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterBar } from '@f1/shared/css/_styledComponents';
import { isEmpty } from '@f1/shared/src/_helpers';
import {
  ComboBox,
  MultiSelect,
  NestedMenu,
  ToggleSwitch
} from '@f1/shared';
import { getRelationshipsWithPermissionList } from '../utils';

function DbaFilterBar ({
  showMerchantAndDownline, // if we display both merchant and downline together
  useMerchantNestedMenu,
  isPartner,
  partnerView,
  merchantGuids,
  relationshipTree,
  currentGuid,
  currentDownlines,
  nestedMenus,
  orphans,
  callback, // only used in components that don't use the store
  // methods from store, just used to update the store for use elsewhere on site
  appState,
  filterData,
  activeFilters,
  activeDownline,
  activeGuid
}) {
  const [merchantView, setMerchantView] = useState(!partnerView);
  const [partnerDataMode, setPartnerDataMode] = useState('downline');
  // Partner Downline data
  const [downlineList, setDownlineList] = useState([]);
  const [downlineSelectedText, setDownlineSelectedText] = useState('');
  const [checkedDownlines, setCheckedDownlines] = useState([]);
  const [overallMerchantCount, setOverallMerchantCount] = useState(0);
  const [allDownlinesChecked, setAllDownlinesChecked] = useState(false);
  const [nestedMerchantItems, setNestedMerchantItems] = useState({});

  // Merchant data
  const [merchantList, setMerchantList] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState('');

  const filterBarWrapper = useRef();

  useEffect(() => { // on mount
    window.addEventListener('resize', handleResize);
    setTimeout(setPagePadding, 100);
    return () => { // on unmount
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    collectLists();
  }, [merchantGuids]);

  const handleResize = () => {
    setPagePadding();
  };

  const setPagePadding = async () => {
    const page = document.getElementsByClassName('mainSitePage');
    const filters = filterBarWrapper?.current || null;
    const height = filters ? filters.offsetHeight : 0;
    // add if to make sure it exists (RTL won't find it)
    if (page[0]) { page[0].style.paddingTop = `${height}px`; }
  };

  const collectLists = async () => {
    // someday, this might (should) be and api call for data,
    // for now we pass data into component from the redux store
    const merchants = merchantGuids.reduce((list, merchant) => (
      [...list].concat({ title: merchant.dba, value: merchant.guid })), []);
    !isEmpty(merchants) && setMerchantList(merchants);
    let activeMerchant = {};
    if (currentGuid.guid) {
      // if a merchant is pre-selected (in the store) use that one
      activeMerchant = currentGuid;
    } else if (!isEmpty(merchantGuids) && merchantGuids[0].guid) {
      // otherwise grab the first in the merchants array if it exists
      [activeMerchant] = merchantGuids;
    }
    setSelectedMerchant(activeMerchant.guid);
    if (!isPartner || partnerDataMode === 'merchant' || merchantView) {
      setMerchant(activeMerchant);
    }
    const hasDownlines = !isEmpty(relationshipTree?.subPartner);
    const allDownlines = hasDownlines
      ? await getRelationshipsWithPermissionList(null, { noPermissionsRequired: true })
      : [];
    const downlines = isEmpty(allDownlines) ? [] : allDownlines.map(row => ({
      value: row.guid,
      title: `${row.dba} (${row.totalMerchantCount} MIDs)`,
      parent: row.parent,
      depth: row.depth
    }));
    !isEmpty(downlines) && setDownlineList(downlines);
    // get count for displaying above downline dropdown
    // Grab the totals for all direct sub-partners, excluding any who's parent is in the same array
    const arrUnique = hasDownlines ? relationshipTree?.subPartner?.filter((obj) => {
      const match = relationshipTree?.subPartner?.find(o => o.guid === obj.parent?.guid);
      return isEmpty(match);
    }) : merchants; // if no downlines, just use the merchants
    // Should now be the total number of merchants under the partner
    const merchantCount = !isEmpty(arrUnique)
      ? arrUnique.reduce((acc, subP) => acc + subP.totalMerchantCount, 0)
      : 0;
    setOverallMerchantCount(merchantCount);
    // If the partner only has 1 dba, make it active
    if (isPartner && merchantCount === 1) { setMerchant(activeMerchant); }
    const nestedMerchantMenuItems = (useMerchantNestedMenu || showMerchantAndDownline)
      ? {
        subPartner: [
          ...(merchantView && !isEmpty(allDownlines) ? allDownlines : []),
          ...(!merchantView && hasDownlines ? relationshipTree.subPartner : []),
          orphans
        ]
      }
      : {};
    setNestedMerchantItems(nestedMerchantMenuItems);
    if (isPartner && partnerDataMode === 'downline') {
      // Only if a partner in downline view pre-select the downline
      if (!isEmpty(currentDownlines)) {
        // on load, preselect any checkboxes that are stored in redux
        setDownlines(currentDownlines);
      } else {
        // if the store is empty, pre-select everything
        const cleanedDownlines = allDownlines.map(datum => ({
          dba: datum.dba,
          guid: datum.guid
        }));
        const preSelectList = allDownlines.length <= 1 ? cleanedDownlines : [{ dba: 'All', guid: 'all' }].concat(cleanedDownlines);
        setDownlines(preSelectList);
      }
    }
  };

  const setMerchant = (selected) => {
    setPartnerDataMode('merchant');
    const newSelected = {
      ...selected,
      // NestedMenu returns { guid, dba }
      // ComboBox returns { title, value }
      value: selected.value || selected.guid,
      title: selected.title || selected.dba
    };
    setSelectedMerchant(newSelected.value);
    // Also update 2 places in the redux store.
    const mappedGuid = { dba: newSelected.title, guid: newSelected.value };
    activeGuid(mappedGuid);
    filterData({ activeFilters: { ...activeFilters, guid: mappedGuid } });
    if (showMerchantAndDownline) { setDownlineSelectedText(''); }
    const cbData = { ...mappedGuid, filterType: 'merchant' };
    callback && callback(cbData);
  };

  const setDownlines = (selected) => {
    if (!merchantView) {
      setPartnerDataMode('downline');
      // if no selection passed, check for selection in store
      const downlinesToCheck = isEmpty(selected) ? currentDownlines : selected;
      const newHeaderTitle = () => {
        const titleString = Array.isArray(downlinesToCheck) && !isEmpty(downlinesToCheck)
          ? downlinesToCheck
            .map(({ dba }) => dba)
            .join(', ')
          : '';
        if (titleString.startsWith('All')) return 'All';
        return titleString;
      };
      setDownlineSelectedText(newHeaderTitle());
      const checked = downlinesToCheck.map(
        downline => ({ title: downline.dba, value: downline.guid })
      );
      setCheckedDownlines(checked);
      if (!isEmpty(selected)) {
        // if something was passed, we want to also update the store.
        activeDownline({
          currentDownlines: downlinesToCheck,
          dashboardRequestGuids: downlinesToCheck
            .filter(downline => downline.guid !== 'all' && downline.guid !== 'no_id')
            .map(downline => downline.guid)
        });
        // also need to update the store for filter data
        filterData({ activeFilters: { ...activeFilters, downline: downlinesToCheck } });
      }
      // setting is the all option is checked. used in internal of multiselect
      const allChecked = checked.filter(item => item.value === 'all');
      setAllDownlinesChecked(!isEmpty(allChecked));
      const active = {
        ...activeFilters,
        guid: {},
        downline: downlinesToCheck,
        filterType: 'downlines'
      };
      callback && callback(active);
    }
  };

  const toggleMerchantView = (checked) => {
    setMerchantView(checked);
    // using filterData dispatch event to send view toggle to store.
    filterData({ activeFilters: { ...activeFilters, viewAsMerchant: checked } });
  };

  useEffect(() => {
    setPartnerDataMode(merchantView ? 'merchant' : 'downline');
  }, [merchantView]);

  return (
    <FilterBar id="dbaFilterBar" fixedAlways ref={filterBarWrapper} role="article" aria-label="DBA filter bar">
      {/* Partner with 1 to many downlines (MultiSelect) */}
      { !merchantView && downlineList.length > 0 && (
        !useMerchantNestedMenu || showMerchantAndDownline
      ) && (
        <MultiSelect
          id="downlines"
          label={`Downline: ${downlineList?.length || 0} with ${overallMerchantCount} MIDs (open + closed)`}
          title={downlineSelectedText}
          selected={downlineSelectedText}
          height={30}
          list={downlineList.length <= 1 ? downlineList : [{ title: 'All', value: 'all' }].concat(downlineList)}
          checkbox
          allChecked={partnerDataMode === 'downline' ? allDownlinesChecked : false}
          checkedItems={partnerDataMode === 'downline' ? checkedDownlines : []}
          type="guid"
          callback={setDownlines}
          tooltip={`You have ${downlineList?.length || 0} downlines with a total of (${overallMerchantCount}) Merchants (includes closed merchants)`}
          searchKeys={[{ title: 'relationships', value: 'title' }]}
        />
      )}
      { (!merchantView && merchantList.length > 0 && showMerchantAndDownline) && (
        <span style={{ margin: '22px 10px 0px', maxWidth: '25px', minWidth: '25px' }}>OR</span>
      )}
      {/* Partner - merchants NestedMenu */}
      { !merchantView &&
        (useMerchantNestedMenu || showMerchantAndDownline) &&
        overallMerchantCount > 0 &&
      (
        <NestedMenu
          label="Merchant"
          id="partnerTree"
          items={nestedMerchantItems}
          title="dba"
          value="guid"
          current={(partnerDataMode === 'merchant' || !showMerchantAndDownline) ? currentGuid : {}}
          callback={setMerchant}
          nestingCallback={appState}
          nestedMenus={nestedMenus}
        />
      )}
      {/* Merchant with at least one guid */}
      { merchantView && merchantList.length > 0 && (
        <ComboBox
          id="dbaNameDD"
          type="guid"
          label="DBA Name"
          list={merchantList}
          callback={setMerchant}
          selected={selectedMerchant}
        />
      )}
      {isPartner ? (
        <ToggleSwitch
          id="merchantViewToggle"
          label="Show Merchant View"
          wrapperStyle={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginLeft: 'auto',
            alignSelf: 'flex-end',
            minWidth: '236px'
          }}
          callback={toggleMerchantView}
          checked={merchantView}
        />
      ) : null}
    </FilterBar>
  );
}

DbaFilterBar.propTypes = {
  isPartner: PropTypes.bool,
  partnerView: PropTypes.bool,
  useMerchantNestedMenu: PropTypes.bool,
  showMerchantAndDownline: PropTypes.bool,
  merchantGuids: PropTypes.oneOfType([PropTypes.array]),
  relationshipTree: PropTypes.oneOfType([PropTypes.object]),
  currentGuid: PropTypes.oneOfType([PropTypes.object]),
  currentDownlines: PropTypes.oneOfType([PropTypes.array]),
  callback: PropTypes.func, // only used when parent does not use store
  activeFilters: PropTypes.oneOfType([PropTypes.object]),
  orphans: PropTypes.oneOfType([PropTypes.object]),
  nestedMenus: PropTypes.shape({
    partnerTree: PropTypes.string
  }),
  // methods from redux store, used to update store for use on other pages
  activeDownline: PropTypes.func,
  filterData: PropTypes.func,
  activeGuid: PropTypes.func,
  appState: PropTypes.func
};
DbaFilterBar.defaultProps = {
  isPartner: false,
  partnerView: false,
  merchantGuids: [],
  relationshipTree: {},
  currentGuid: {},
  currentDownlines: [],
  activeFilters: {},
  callback: null,
  // merchant NestedMenu
  showMerchantAndDownline: false,
  useMerchantNestedMenu: false,
  orphans: {},
  nestedMenus: { partnerTree: '' },
  // methods from redux store, used to update store for use on other pages
  appState: () => {}, // merchant NestedMenu
  activeDownline: () => {},
  filterData: () => {},
  activeGuid: () => {}
};

export default DbaFilterBar;
