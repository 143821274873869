import { getMockValue } from '@f1/shared/src/data/_mockDataHelpers';
import { monthsDropdown, isEmpty } from '@f1/shared/src/_helpers';
import { users } from '../../user/signIn';
import { mockRelationshipList, getMockRelationshipList } from '../../_mockDataHelpers';
import { partnerPortalGuids } from '../../guids';

const makeDataGroup = (options) => {
  const {
    count = getMockValue({ type: 'number', min: 5, max: 20 }),
    prefix = 'CTUH_ADD'
  } = options || {};
  const test = {};
  for (let idx = 0; idx < count; idx += 1) {
    test[`${prefix}_${idx + 1}`] = getMockValue({ type: 'number' });
  }
  return test;
};

const getRelationshipMatches = (options) => {
  const { guid } = options || {};
  const parsedGuids = typeof guid === 'string' && guid.includes('[') ? JSON.parse(guid) : guid;
  const guidsArray = Array.isArray(parsedGuids) ? parsedGuids : [parsedGuids];
  const relationshipList = getMockRelationshipList(options);
  const relationshipMatches = mockRelationshipList.filter(r => guidsArray.includes(r.value));
  return !isEmpty(relationshipMatches) ? relationshipMatches : relationshipList;
};

const createMockAuthorizations = (options) => {
  const relationshipMatches = getRelationshipMatches(options);
  const mockMonths = ['Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug'];
  const mockAuths = mockMonths.map((mockMonth) => {
    const relationshipValues = relationshipMatches.reduce((acc, rMatch) => ({
      ...acc,
      [rMatch.title]: getMockValue({ type: 'number' })
    }), {});
    return {
      date: mockMonth,
      ...relationshipValues,
      count: getMockValue({ type: 'number' })
    };
  });
  return mockAuths;
};

const createMockChargebacks = (options) => {
  const mockMonths = ['Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug'];
  const mockChargebacks = mockMonths.map(mockMonth => ({
    date: mockMonth,
    discover: getMockValue({ type: 'number' }),
    other: getMockValue({ type: 'number' }),
    americanExpress: getMockValue({ type: 'number' }),
    visa: getMockValue({ type: 'number' }),
    dinerClub: getMockValue({ type: 'number' }),
    mastercard: getMockValue({ type: 'number' }),
    ratio: getMockValue({ type: 'ratio' })
  }));
  return mockChargebacks;
};

export const mockPartnerLandingPageDataBackend = (options) => { // BE api response by guid
  const mockRes = {
    portfolioHealth: 50.15,
    volumeCount: {
      authorization: createMockAuthorizations(options),
      authorizationChart: {
        header: {
          label: 'date',
          lines: ['count'],
          biaxial: ['count'],
          currency: true,
          biaxialCurrency: false
        },
        data: [{
          date: 'Feb',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Mar',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Apr',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'May',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Jun',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Jul',
          CTUH_ADD_2: 8095.97,
          count: 21,
          CTUH_ADD_1: 3770.16,
          TPPS_leaderboard: 0
        }, {
          date: 'Aug',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }]
      },
      settlement: Array(7).fill().map((_, i) => ({
        date: monthsDropdown[i + 1].title,
        count: getMockValue({ type: 'number' }),
        ...makeDataGroup({ count: 32 }),
        TPPS_leaderboard: getMockValue({ type: 'number' })
      })),
      settlementChart: {
        header: {
          label: 'date',
          lines: ['count'],
          biaxial: ['count'],
          currency: true,
          biaxialCurrency: false
        },
        data: [{
          date: 'Feb',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Mar',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Apr',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'May',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Jun',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }, {
          date: 'Jul',
          CTUH_ADD_2: 1751.32,
          count: 30,
          CTUH_ADD_1: 257.50,
          TPPS_leaderboard: 34.72
        }, {
          date: 'Aug',
          CTUH_ADD_2: 0,
          count: 0,
          CTUH_ADD_1: 0,
          TPPS_leaderboard: 0
        }]
      },
      chargeback: createMockChargebacks(options),
      chargebackChart: {
        header: {
          label: 'date',
          lines: ['ratio'],
          biaxial: ['ratio'],
          currency: false,
          biaxialCurrency: false
        },
        data: [{
          date: 'Feb',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }, {
          date: 'Mar',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }, {
          date: 'Apr',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }, {
          date: 'May',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }, {
          date: 'Jun',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }, {
          date: 'Jul',
          discover: 1,
          other: 20,
          americanExpress: 0,
          visa: 2,
          dinerClub: 0,
          mastercard: 1,
          ratio: 0.80
        }, {
          date: 'Aug',
          discover: 0,
          other: 0,
          americanExpress: 0,
          visa: 0,
          dinerClub: 0,
          mastercard: 0,
          ratio: 0.00
        }]
      }
    },
    top5LeastProducingMerchants: [{
      mid: 'cPSLzxdZnXgniWh',
      name: 'dr. YmYktzbSChNGQhy d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+cpslzxdznxgniwh@gmail.com',
      value: 0,
      dba_name: 'CTUH_ADD_2_closed-3'
    }, {
      mid: 'muwPxi9dSgo1NpT',
      name: 'dr. qKFpBTzgQAihujr d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+muwpxi9dsgo1npt@gmail.com',
      value: 0,
      dba_name: 'CTUH_ADD_2_closed-2'
    }, {
      mid: '49XUhRUUGRlVOnf',
      name: 'dr. CmQeZNJUQoBGiwI d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+49xuhruugrlvonf@gmail.com',
      value: 0,
      dba_name: 'CTUH_ADD_2_closed-4'
    }, {
      mid: '475uTbSHmheOTie',
      name: 'dr. NPqNXHaCuVlXXUq d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+475utbshmheotie@gmail.com',
      value: 0,
      dba_name: 'CTUH_ADD_2_closed-5'
    }, {
      mid: 'hTVJsSbn5Hj7zma',
      name: 'dr. OIBNTatwArkoRTF d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+htvjssbn5hj7zma@gmail.com',
      value: 0,
      dba_name: 'CTUH_ADD_2_closed-6'
    }],
    top5MostProducingMerchants: [{
      mid: 'mqnIh2CGm3FgTd1',
      name: 'dr. ewxsfOTZTZNIFTc d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+mqnih2cgm3fgtd1@gmail.com',
      value: 921.83,
      dba_name: 'CTUH_ADD_2_stopped'
    }, {
      mid: 'tNw92m6T6u8Orl4',
      name: 'dr. xCMhSuOQwhIjWKY d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+tnw92m6t6u8orl4@gmail.com',
      value: 515.59,
      dba_name: 'CTUH_ADD_2_stopped'
    }, {
      mid: 'FGhuh0SK28nU6Os',
      name: 'dr. XTpmpwfJhgGwdde d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+fghuh0sk28nu6os@gmail.com',
      value: 385.33,
      dba_name: 'TPPS_leaderboard_mid'
    }, {
      mid: '6DM0mtlnU8EeiLV',
      name: 'dr. phMwkbAWvnbRYbK d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+6dm0mtlnu8eeilv@gmail.com',
      value: 156.45,
      dba_name: 'CTUH_ADD_1_TPPS_txnBarGraph'
    }, {
      mid: 'gvlUY4cQfQdHXn7',
      name: 'dr. nxtepjlzDHJApKa d.c.',
      phone: '+15125536624',
      email: 'f1paymentsengineering+gvluy4cqfqdhxn7@gmail.com',
      value: 156.45,
      dba_name: 'CTUH_ADD_2_TPPS_txnBarGraph'
    }],
    top5UnhealthyPartners: [{
      onboardedMerchants: 1,
      relationshipId: '3fc9a5e6-e256-4a27-8597-4da37683bfbc',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_19',
      phone: '+15125536624',
      email: 'f1paymentsengineering+mpyqtybk@gmail.com'
    }, {
      onboardedMerchants: 1,
      relationshipId: 'cb6034d6-52a8-41ab-a3f8-1ef6cfb5c3ff',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_20',
      phone: '+15125536624',
      email: 'f1paymentsengineering+lddv05tt@gmail.com'
    }],
    top5HealthyPartners: [{
      onboardedMerchants: 25,
      relationshipId: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84',
      relationshipName: 'CTUH_ADD_2',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      phone: '+15125536624',
      email: users.partner.hasAllDashboardData.email
    }, {
      onboardedMerchants: 13,
      relationshipId: 'd289c5d3-7195-49a3-9fa6-1a8ef04056e6',
      relationshipName: 'CTUH_ADD_1',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      phone: '+15125536624',
      email: users.partner.hasAllDashboardData.email
    }, {
      onboardedMerchants: 1,
      relationshipId: 'fd975260-562c-4b23-83a1-bcc712b0cade',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_18',
      phone: '+15125536624',
      email: 'f1paymentsengineering+qydsxdmk@gmail.com'
    }],
    top5LeastProducingPartners: [{
      value: 6.86,
      relationshipId: 'fd975260-562c-4b23-83a1-bcc712b0cade',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_18',
      phone: '+15125536624',
      email: 'f1paymentsengineering+qydsxdmk@gmail.com'
    }, {
      value: 34.72,
      relationshipId: 'cb6034d6-52a8-41ab-a3f8-1ef6cfb5c3ff',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_20',
      phone: '+15125536624',
      email: 'f1paymentsengineering+lddv05tt@gmail.com'
    }],
    top5MostProducingPartners: [{
      value: 1751.32,
      relationshipId: 'c7f99ba1-ab36-4d0a-a638-ea80e0564d84',
      relationshipName: 'CTUH_ADD_2',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      phone: '+15125536624',
      email: users.partner.hasAllDashboardData.email
    }, {
      value: 385.33,
      relationshipId: '3fc9a5e6-e256-4a27-8597-4da37683bfbc',
      relationshipName: 'TPPS_leaderboard',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_19',
      phone: '+15125536624',
      email: 'f1paymentsengineering+mpyqtybk@gmail.com'
    }, {
      value: 257.50,
      relationshipId: 'd289c5d3-7195-49a3-9fa6-1a8ef04056e6',
      relationshipName: 'CTUH_ADD_1',
      partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
      phone: '+15125536624',
      email: users.partner.hasAllDashboardData.email
    }],
    chargebackPortfolioHealth: {
      healthyCount: 65,
      healthyMerchants: [{
        merchantGuid: 'f893e585-a2b7-47cd-8524-37491bdfd15f',
        mid: 'mlTeHJPGoFq2jQ7',
        dbaName: 'GSzZjzd1J6VOiWO',
        merchantEmail: 'f1paymentsengineering+mltehjpgofq2jq7@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'ab6bc83b-f107-4eda-a686-9efccbd6f780',
        mid: 'i4dZIGEHtfbRqLT',
        dbaName: 'fB31ksdPB3CEkBe',
        merchantEmail: 'f1paymentsengineering+i4dzigehtfbrqlt@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '77f302f2-5d4f-4742-8b63-7307429a12ba',
        mid: '6DM0mtlnU8EeiLV',
        dbaName: 'CTUH_ADD_1_TPPS_txnBarGraph',
        merchantEmail: 'f1paymentsengineering+6dm0mtlnu8eeilv@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '2879b631-f000-4702-b7b6-e3eb6046a706',
        mid: 'BrvvhlIzC1NxKHc',
        dbaName: 'CTUH_ADD_1_TPPS_income',
        merchantEmail: 'f1paymentsengineering+brvvhlizc1nxkhc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '1c66c251-bd77-45ad-b55f-d43f8d9c3e35',
        mid: 'K0sESlZEpMOwet1',
        dbaName: 'CTUH_ADD_1_noStart',
        merchantEmail: 'f1paymentsengineering+k0seslzepmowet1@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '938e4a42-c848-41c4-a2eb-747b31a8dde0',
        mid: 'IMlUOvHYcGe4Pdy',
        dbaName: 'CTUH_ADD_1_testTxn',
        merchantEmail: 'f1paymentsengineering+imluovhycge4pdy@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'bc4ea2b7-5232-4137-aeeb-0659f34a1152',
        mid: 'a6zyac7M9vhtPDo',
        dbaName: 'CTUH_ADD_1_shutdown',
        merchantEmail: 'f1paymentsengineering+a6zyac7m9vhtpdo@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '5a21bc56-160f-4d94-b5df-96d96aa1bae1',
        mid: 'ZiZlbNlzShGFgkc',
        dbaName: 'CTUH_ADD_1_stopped',
        merchantEmail: 'f1paymentsengineering+zizlbnlzshgfgkc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: partnerPortalGuids.merchantGuids.default,
        mid: 'ywrKclU1DHDDBLT',
        dbaName: 'CTUH_ADD_1_opened-0',
        merchantEmail: 'f1paymentsengineering+ywrkclu1dhddblt@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'fc10b25f-bbcd-441c-b670-07e28a63d9e7',
        mid: '2uyBze5mHqN0C52',
        dbaName: 'CTUH_ADD_1_closed-0',
        merchantEmail: 'f1paymentsengineering+2uybze5mhqn0c52@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: partnerPortalGuids.merchantGuids.secondWithData,
        mid: 'aZ7ZREhhSOM9xz7',
        dbaName: 'CTUH_ADD_1_opened-1',
        merchantEmail: 'f1paymentsengineering+az7zrehhsom9xz7@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '071d33c0-5801-4b05-b699-af3863b9d853',
        mid: 'SML48FKcRxFv93B',
        dbaName: 'CTUH_ADD_1_closed-1',
        merchantEmail: 'f1paymentsengineering+sml48fkcrxfv93b@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '8ab408e5-b318-4589-bba7-37c3750d2f20',
        mid: 'vX2A5ge3R9RzKqX',
        dbaName: 'CTUH_ADD_1_opened-2',
        merchantEmail: 'f1paymentsengineering+vx2a5ge3r9rzkqx@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '62ad63f7-9d2d-49c4-8ce6-18ecbc932beb',
        mid: 'dgN9qSgYbrjafWb',
        dbaName: 'CTUH_ADD_1_closed-2',
        merchantEmail: 'f1paymentsengineering+dgn9qsgybrjafwb@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '83d67c25-f49e-4a06-8bea-fd4022ebad40',
        mid: 'Fmf8wGDV4ihjeIu',
        dbaName: 'CTUH_ADD_1_opened-3',
        merchantEmail: 'f1paymentsengineering+fmf8wgdv4ihjeiu@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '53ace820-0bed-4aaa-8eb0-d7060f68042e',
        mid: '2AorTfRwUOMJmOF',
        dbaName: 'CTUH_ADD_1_closed-3',
        merchantEmail: 'f1paymentsengineering+2aortfrwuomjmof@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'b7bf597e-f612-4462-b063-21bd706051d5',
        mid: 'OZobeXMCv967YeJ',
        dbaName: 'CTUH_ADD_1_opened-4',
        merchantEmail: 'f1paymentsengineering+ozobexmcv967yej@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '6758be57-3521-4a7c-acf6-6119abcd30ed',
        mid: 'uIjPw3IUL9cW9s3',
        dbaName: 'CTUH_ADD_1_closed-4',
        merchantEmail: 'f1paymentsengineering+uijpw3iul9cw9s3@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '0721550f-ea2c-4af1-8f16-f14ae4b69428',
        mid: '4NoEgcK9cN5sIbV',
        dbaName: 'CTUH_ADD_1_opened-5',
        merchantEmail: 'f1paymentsengineering+4noegck9cn5sibv@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'e5496a79-4069-44bb-bc86-bfb6ea84e650',
        mid: '5MTQ1Zw0IiLtNng',
        dbaName: 'CTUH_ADD_1_closed-5',
        merchantEmail: 'f1paymentsengineering+5mtq1zw0iiltnng@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '453e2eea-8826-4eba-940b-8175fadb9528',
        mid: 'O3GXEgScjYbdSxf',
        dbaName: 'CTUH_ADD_1_opened-6',
        merchantEmail: 'f1paymentsengineering+o3gxegscjybdsxf@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '82530e9a-aa76-42dd-8570-c93207b340f5',
        mid: 'KtucxI43VhUhohY',
        dbaName: 'CTUH_ADD_1_closed-6',
        merchantEmail: 'f1paymentsengineering+ktucxi43vhuhohy@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'dce037db-b77e-462f-84f7-f165c71558e8',
        mid: 'fnVzxpnL5HVYqyM',
        dbaName: 'TPPS_leaderboard_mid',
        merchantEmail: 'f1paymentsengineering+fnvzxpnl5hvyqym@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: '70e369a4-3d07-434e-b806-987db21b83da',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_18',
        partnerEmail: 'f1paymentsengineering+qydsxdmk@gmail.com',
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '18a2a63d-1742-4799-8ef6-4bba1f65d6c1',
        mid: 'IyPlCmGe6ObkKw7',
        dbaName: 'CTUH_ADD_2_TPPS_txnBarGraph',
        merchantEmail: 'f1paymentsengineering+iyplcmge6obkkw7@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '1f0b2083-8c66-4574-bdb0-b25a19712fdc',
        mid: 'qhD3smwz4ubhWIi',
        dbaName: 'CTUH_ADD_2_TPPS_income',
        merchantEmail: 'f1paymentsengineering+qhd3smwz4ubhwii@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '47f2780f-a4aa-4e11-9d32-2224ad5abe30',
        mid: 'n9Gfv7VhgCcAtLK',
        dbaName: 'CTUH_ADD_2_noStart',
        merchantEmail: 'f1paymentsengineering+n9gfv7vhgccatlk@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'cef60ea9-d046-4336-a21f-54e4e4a47721',
        mid: 'IP7wNQ2zlb2sOJr',
        dbaName: 'CTUH_ADD_2_testTxn',
        merchantEmail: 'f1paymentsengineering+ip7wnq2zlb2sojr@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '2bfc6521-3f0b-4e46-ab1f-bd193005bd54',
        mid: 'BAtp6NZGNokvXtM',
        dbaName: 'CTUH_ADD_2_shutdown',
        merchantEmail: 'f1paymentsengineering+batp6nzgnokvxtm@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '1eaf6d1d-7661-4c1b-94cf-65da03010589',
        mid: 'tNw92m6T6u8Orl4',
        dbaName: 'CTUH_ADD_2_stopped',
        merchantEmail: 'f1paymentsengineering+tnw92m6t6u8orl4@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'e04b4105-eedf-4a0f-8e01-bca76a40fa8c',
        mid: 'DxyKcFU7jM4Iqlm',
        dbaName: 'CTUH_ADD_2_opened-0',
        merchantEmail: 'f1paymentsengineering+dxykcfu7jm4iqlm@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '822bbb27-9671-4b6a-8040-5b9788075249',
        mid: '1KQcuDLs26mg2wP',
        dbaName: 'CTUH_ADD_2_closed-0',
        merchantEmail: 'f1paymentsengineering+1kqcudls26mg2wp@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'd229718d-9960-4e53-b0dd-3e4cf4c1c0df',
        mid: 'FHTQx1OONrVQfio',
        dbaName: 'CTUH_ADD_2_opened-1',
        merchantEmail: 'f1paymentsengineering+fhtqx1oonrvqfio@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'c3edde32-3696-4894-814b-43f7baadf40e',
        mid: 'cTlC9GZnWCzhaMU',
        dbaName: 'CTUH_ADD_2_closed-1',
        merchantEmail: 'f1paymentsengineering+ctlc9gznwczhamu@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '10a8005a-dcb6-474f-99f0-2dce6f69d6f0',
        mid: 'iAjDwSC2WJsvl8L',
        dbaName: 'CTUH_ADD_2_opened-2',
        merchantEmail: 'f1paymentsengineering+iajdwsc2wjsvl8l@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '2f158d23-0730-4b13-bfed-ec4737ba8170',
        mid: 'muwPxi9dSgo1NpT',
        dbaName: 'CTUH_ADD_2_closed-2',
        merchantEmail: 'f1paymentsengineering+muwpxi9dsgo1npt@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'f6d29a7b-0618-486b-97d4-1a7a3eb8a4bb',
        mid: '19Ex8Ausk0saL3F',
        dbaName: 'CTUH_ADD_2_opened-3',
        merchantEmail: 'f1paymentsengineering+19ex8ausk0sal3f@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '51f94b3e-ddf1-46bc-a576-2c4dc736a2cb',
        mid: 'R6fvqAzqcQagsg8',
        dbaName: 'CTUH_ADD_2_closed-3',
        merchantEmail: 'f1paymentsengineering+r6fvqazqcqagsg8@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '98c1b001-598c-4364-a9e7-8e09f6513b22',
        mid: 'KlF59mVlCyC6pfC',
        dbaName: 'CTUH_ADD_2_opened-4',
        merchantEmail: 'f1paymentsengineering+klf59mvlcyc6pfc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'e18291cf-759e-4e42-8a65-6c3945683320',
        mid: '49XUhRUUGRlVOnf',
        dbaName: 'CTUH_ADD_2_closed-4',
        merchantEmail: 'f1paymentsengineering+49xuhruugrlvonf@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '05c30d6b-1eaa-4dea-8d28-73ac6cbcf285',
        mid: 'gmZlTUSCtsu3WN2',
        dbaName: 'CTUH_ADD_2_opened-5',
        merchantEmail: 'f1paymentsengineering+gmzltusctsu3wn2@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '26d44ab3-f393-4ef9-a9ed-d7927a5a177f',
        mid: '475uTbSHmheOTie',
        dbaName: 'CTUH_ADD_2_closed-5',
        merchantEmail: 'f1paymentsengineering+475utbshmheotie@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'ffe6add5-aa9d-4257-8513-aeb45173ef8d',
        mid: 'uRyAvJZs8MyfW4z',
        dbaName: 'CTUH_ADD_2_opened-6',
        merchantEmail: 'f1paymentsengineering+uryavjzs8myfw4z@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'a46f0195-3be1-4445-953f-1c6b78d120d8',
        mid: 'hTVJsSbn5Hj7zma',
        dbaName: 'CTUH_ADD_2_closed-6',
        merchantEmail: 'f1paymentsengineering+htvjssbn5hj7zma@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '526b2463-7458-4d5c-9a56-c97375d48d9c',
        mid: 'FGhuh0SK28nU6Os',
        dbaName: 'TPPS_leaderboard_mid',
        merchantEmail: 'f1paymentsengineering+fghuh0sk28nu6os@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: '827126b7-07cd-498e-97f7-5bd4c253523b',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_19',
        partnerEmail: 'f1paymentsengineering+mpyqtybk@gmail.com',
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '3b103038-7a07-498a-9a7f-9c528ffc6797',
        mid: 'gvlUY4cQfQdHXn7',
        dbaName: 'CTUH_ADD_2_TPPS_txnBarGraph',
        merchantEmail: 'f1paymentsengineering+gvluy4cqfqdhxn7@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '726fad40-e3e4-43cf-b16d-f6012620e23a',
        mid: 'hcEUArhXFi5x2nA',
        dbaName: 'CTUH_ADD_2_TPPS_income',
        merchantEmail: 'f1paymentsengineering+hceuarhxfi5x2na@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '041417d0-1b83-4a02-823b-f786944a5fae',
        mid: 'q1wyMz4G9Oe3MgO',
        dbaName: 'CTUH_ADD_2_noStart',
        merchantEmail: 'f1paymentsengineering+q1wymz4g9oe3mgo@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'c7e33651-4c24-4e4e-bc8b-a4c1fa977da1',
        mid: '8V9IAFC1G23u0DS',
        dbaName: 'CTUH_ADD_2_testTxn',
        merchantEmail: 'f1paymentsengineering+8v9iafc1g23u0ds@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'b2164c74-662f-4e92-8a69-a7046e04bb1f',
        mid: 'JNxNv2SniNHIy44',
        dbaName: 'CTUH_ADD_2_shutdown',
        merchantEmail: 'f1paymentsengineering+jnxnv2sninhiy44@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '13370db1-fee4-4b68-95a2-ff8de8f17d7b',
        mid: 'mqnIh2CGm3FgTd1',
        dbaName: 'CTUH_ADD_2_stopped',
        merchantEmail: 'f1paymentsengineering+mqnih2cgm3fgtd1@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '566dc791-c69d-451e-bd4b-e661d5dde29d',
        mid: 'dagIIeJUb1Xa196',
        dbaName: 'CTUH_ADD_2_opened-0',
        merchantEmail: 'f1paymentsengineering+dagiiejub1xa196@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'b0d42870-41d7-476a-9ee5-b631c3e55f2f',
        mid: 'dzGXya0xjaEVbtC',
        dbaName: 'CTUH_ADD_2_closed-0',
        merchantEmail: 'f1paymentsengineering+dzgxya0xjaevbtc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'd70bf380-3a4a-4622-9670-5f3a6cf81c49',
        mid: 'dDTsEJz5CiUo3ow',
        dbaName: 'CTUH_ADD_2_opened-1',
        merchantEmail: 'f1paymentsengineering+ddtsejz5ciuo3ow@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '16360978-715f-4e85-bd35-22c3539600b2',
        mid: 'KaMS2J0RYDnTxXz',
        dbaName: 'CTUH_ADD_2_closed-1',
        merchantEmail: 'f1paymentsengineering+kams2j0rydntxxz@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '3c1c45c3-992d-46cb-96b9-aa64106fb3ca',
        mid: 'NmP5V2DHT9uOTWC',
        dbaName: 'CTUH_ADD_2_opened-2',
        merchantEmail: 'f1paymentsengineering+nmp5v2dht9uotwc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'f680866d-8393-471c-ae7f-98284b60ab66',
        mid: 'WgTeIPK0M8EMzry',
        dbaName: 'CTUH_ADD_2_closed-2',
        merchantEmail: 'f1paymentsengineering+wgteipk0m8emzry@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'b4b4a37f-5eb6-44ee-b73f-5b519ecc40c3',
        mid: '9ojBEzhrxRtvUKk',
        dbaName: 'CTUH_ADD_2_opened-3',
        merchantEmail: 'f1paymentsengineering+9ojbezhrxrtvukk@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '105c2c01-e537-4608-872f-37666fc4f42a',
        mid: 'cPSLzxdZnXgniWh',
        dbaName: 'CTUH_ADD_2_closed-3',
        merchantEmail: 'f1paymentsengineering+cpslzxdznxgniwh@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '4fc8f6da-06e9-47d7-b7e5-63cf754f209a',
        mid: 'W6987frocV2isaU',
        dbaName: 'CTUH_ADD_2_opened-4',
        merchantEmail: 'f1paymentsengineering+w6987frocv2isau@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'f0c4a921-5dd3-4b95-adca-92f7852db0df',
        mid: 'UGrCy9x9G0QHIwf',
        dbaName: 'CTUH_ADD_2_closed-4',
        merchantEmail: 'f1paymentsengineering+ugrcy9x9g0qhiwf@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '492ca204-76c2-424b-9f51-5c491f0c38a6',
        mid: 'BlMxQPLfDOrEAZs',
        dbaName: 'CTUH_ADD_2_opened-5',
        merchantEmail: 'f1paymentsengineering+blmxqplfdoreazs@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '6b2ca5c6-e76d-4eb5-82b2-ff664ee0e209',
        mid: 'ZzHmRJG1RiLMDwE',
        dbaName: 'CTUH_ADD_2_closed-5',
        merchantEmail: 'f1paymentsengineering+zzhmrjg1rilmdwe@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: '66c9c005-e67e-4b66-8c74-33470250ab1b',
        mid: 'nrOh81hlU5N5MRp',
        dbaName: 'CTUH_ADD_2_opened-6',
        merchantEmail: 'f1paymentsengineering+nroh81hlu5n5mrp@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'b1d09bfb-06cb-46e3-9937-11fe14f0e7d6',
        mid: 'v3trVTDOSfRMTbb',
        dbaName: 'CTUH_ADD_2_closed-6',
        merchantEmail: 'f1paymentsengineering+v3trvtdosfrmtbb@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: 'c0b2d126-9d53-4553-96ae-6339cf13eba4',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_17',
        partnerEmail: users.partner.hasAllDashboardData.email,
        partnerPhone: '+15125536624'
      }, {
        merchantGuid: 'e83814eb-9c0c-43f3-95ea-60461732ff28',
        mid: '8CZCagcAP8yvfoc',
        dbaName: 'TPPS_leaderboard_mid',
        merchantEmail: 'f1paymentsengineering+8czcagcap8yvfoc@gmail.com',
        merchantPhone: '+15125536624',
        partnerId: '8d400d25-6fee-402a-8d55-f4dc5eb0b3a1',
        partnerName: 'PostCreateTestUsersHandler_8bXtYjnh_20',
        partnerEmail: 'f1paymentsengineering+lddv05tt@gmail.com',
        partnerPhone: '+15125536624'
      }],
      inDangerCount: 0,
      inDangerMerchants: [],
      onProgramCount: 0,
      onProgramMerchants: [],
      unhealthyCount: 0,
      unhealthyMerchants: []
    },
    partnerMonthlyPerformance: [{
      total: 3,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 0,
      month: '2020-02',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 3,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 0,
      month: '2020-03',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 3,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 0,
      month: '2020-04',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 3,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 0,
      month: '2020-05',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 3,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 0,
      month: '2020-06',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 27,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 8,
      month: '2020-07',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 16,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': 0
    }, {
      total: 41,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Onboarded': 13,
      month: '2020-08',
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Onboarded': 25,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_20 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_18 TPPS_leaderboard Closed': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_19 TPPS_leaderboard Onboarded': 0,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_1 Closed': -8,
      'PostCreateTestUsersHandler_8bXtYjnh_17 CTUH_ADD_2 Closed': -16
    }],
    level: 1
  };
  return mockRes;
};

export const mockPartnerLandingPageGet = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  const backendMockData = mockPartnerLandingPageDataBackend(options);
  return { [guid]: backendMockData };
};
