import {
  chartHeader,
  formatDateForFEView,
  formatNumber,
  isEmpty
} from '../../_helpers';

export const reportReserves = {
  frontendDetails: (schema, version) => {
    if (version === '1.0') {
      return {
        chartData: {
          header: {
            ...chartHeader,
            ...schema?.chart?.header,
            negatives: true,
            stacked: true
          },
          data: schema?.chart?.data || []
        },
        tableData: !isEmpty(schema?.table)
          ? schema?.table?.map(reserveRow => ({
            date: formatDateForFEView(reserveRow.date),
            reserveBalance: formatNumber(reserveRow.reserveBalance, { currency: true }),
            heldBalance: formatNumber(reserveRow.heldBalance, { currency: true }),
            totalMerchantPosition:
            formatNumber(reserveRow.totalMerchantPosition, { currency: true })
          }))
          : []
      };
    }
    return {
      chartData: {},
      tableData: []
    };
  },
  frontendCurrent: (schema, version) => {
    if (version === '1.0') {
      return !isEmpty(schema?.reservesTabularData)
        ? schema?.reservesTabularData?.map(reserveRow => ({
          mid: reserveRow.mid,
          relationship: reserveRow.relationshipName,
          reserveBalance: formatNumber(reserveRow.reserveBalance, { currency: true }),
          heldBalance: formatNumber(reserveRow.heldBalance, { currency: true }),
          totalMerchantPosition:
            formatNumber(reserveRow.totalMerchantPosition, { currency: true })
        }))
        : [];
    }
    return [];
  }
};

export default reportReserves;
