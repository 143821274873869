import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from '@f1/shared/src/routing/withRouter';

import * as actionCreators from '../../redux/actions/actionCreators';
import { footerCSS } from './_styles';

function mapStateToProps (state) {
  return {
    isAuthenticated: state.authenticate.isAuthenticated,
    filterData: state.filterData
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export class BoundFooterLink extends React.Component {
  handleClick = (e) => {
    const {
      isAuthenticated,
      filterData
    } = this.props;
    if (isAuthenticated) {
      const { currentTarget } = e;
      if (currentTarget.href.toLowerCase().includes('signout')) {
        e.preventDefault();
        this.signOut();
        return;
      }
      if (currentTarget.className.includes('pLink')) {
        filterData({ pageNum: 1 });
      }
    }
  }

  signOut = () => {
    // TODO: MER-1068 actually sign user out of cognito
    const { navigate, resetStore } = this.props;
    resetStore();
    navigate('/signin');
  }

  render () {
    const { link, type } = this.props;
    return (
      <li className="link" style={footerCSS.li} key={link.text}>
        <Link
          className={`${link.text.replace('/', '_').toLowerCase()}_${type} ${type}`}
          style={{ color: 'white' }}
          to={link.href}
          onClick={link.click ? link.click : e => this.handleClick(e)}
        >
          {link.text}
        </Link>
      </li>
    );
  }
}

BoundFooterLink.propTypes = {
  type: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.object]),
  isAuthenticated: PropTypes.bool,
  filterData: PropTypes.func,
  navigate: PropTypes.func,
  resetStore: PropTypes.func
};

BoundFooterLink.defaultProps = {
  type: 'sLink',
  link: {},
  isAuthenticated: false,
  filterData: () => {},
  navigate: () => {},
  resetStore: () => {}
};
const FooterLink = connect(mapStateToProps, mapDispatchToProps)(BoundFooterLink);

export default withRouter(FooterLink);
