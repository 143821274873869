import {
  addMonthsToYearMonth,
  chartHeader,
  dataExists,
  getThisYearMonth,
  useMockDbData,
  useStubData,
  envIsLocalOnly,
  envIsStageOnly,
  envIsProd,
  handleVersionHeaderError,
  isEmpty,
  sharedGetInnerAlertBarState,
  triggerLogError,
  logError,
  getUrlObject,
  endpoint,
  ignoreCase,
  transformData,
  dedupeList
} from '@f1/shared/src/_helpers';
import { handleSignOut, sharedIsTokenValid } from '@f1/shared/src/_pollingUtils';
import swal from 'sweetalert';
import { apiCall, fetchCall } from '@f1/shared/src/_api';
import { apiGetAllPageData } from '@f1/shared/src/_apiGetAllPageData';
import { getPreferredRelationships } from '@f1/shared/src/_templateHelpers';
import { addToLocalDB, clearLocalDB } from '@f1/shared/src/_dataCompression';
import { getCustomSettingsShared, saveCustomSettingsShared } from '@f1/shared/src/_customSettings';
import store from './redux/store';
import updatePage from './redux/reducers/updatePage';
import { getMockData } from './data/stubDataMap';
import { siteLinks } from './data/navigation';
import { partnerHierarchyTemplate } from './data/partner/templates/partnerHierarchyTemplate';
import { notificationConfigTemplate } from './data/partnerPortal/templates/notificationConfigTemplate';
import { notificationBellTemplate } from './data/partnerPortal/templates/notificationBellTemplate';
import { mockNotificationConfigGet } from './data/partnerPortal/notificationConfig';
import { mockNotificationBellGet } from './data/partnerPortal/notificationBell';
import { portalSettingsStructure } from './_customSettingsPortal';

export const createCsrfHeader = () => {
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate || {};
  const { accessToken = {}, portalSignedInAsEmail = '' } = user || {};
  const { csrfToken = '' } = accessToken || {};
  return {
    'x-csrf-token': csrfToken,
    ...!isEmpty(portalSignedInAsEmail) && { 'portal-signed-in-as-email': portalSignedInAsEmail }
  };
};

export const updateDropdownHeight = async () => {
  const fields = document.querySelector('.fields');
  const page = document.querySelector('#page');
  if (!isEmpty(fields) && !isEmpty(page)) {
    const pageBottom = page.getBoundingClientRect().bottom;
    const fieldsBottom = fields.getBoundingClientRect().bottom;
    await store.dispatch(updatePage({
      type: 'UPDATE_PAGE',
      dropdown: {
        listHeight: pageBottom - fieldsBottom
      }
    }));
  }
};

export const handleApiError = async (err, options) => {
  const {
    requestGuidDetails = null,
    isErrorLogger = false,
    missingRequiredToken = false,
    disableAllAlerts = false,
    callback = null,
    customErrors = [],
    alert40x = false,
    customMessageMap = {}
  } = options || {};
  await store.dispatch({
    type: 'TOGGLE_LOADER',
    loading: false
  });
  const { response } = err || {};
  const { status } = response || {};
  const { serverMaintenance } = store.getState().appState;
  const customError = !isEmpty(customErrors)
    ? customErrors.find(error => !isEmpty(response) && error.code === status)
    : null;
  if ((envIsStageOnly() || envIsProd()) && status === 426) {
    // user's version header (in prod/stage only) different than the latest push
    handleVersionHeaderError();
  } else if (!isEmpty(response) && status === 423) { // all APIs down
    if (!serverMaintenance) {
      await store.dispatch({
        type: 'APP_STATE',
        serverMaintenance: true
      });
      window.location.reload();
    }
  } else if (!useStubData && // if developing locally, don't sign out on 401
  (!isEmpty(response) && status === 401 && !alert40x && isErrorLogger !== true)
  ) {
    // unauthorized user trying to access a page that requires a token
    // sign the user out of the store
    await store.dispatch({
      type: 'RESET_STORE'
    });
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  } else if (disableAllAlerts) { // no alert bars for any errors
    if (!isEmpty(callback)) {
      callback();
    }
  } else if (!isEmpty(customError) && customError.noAlert) { // custom error with no alert
    if (!isEmpty(customError.cb)) { // custom callback for the specified error code
      customError.cb();
    } else if (!isEmpty(callback)) { // fallback callback for all other errors
      callback();
    }
  } else {
    const passedError = !isEmpty(customMessageMap) && customMessageMap[status]
      ? customMessageMap[status]
      : err;
    const { alertBarMessage } = getInnerAlertBarState({ type: 'warning', data: passedError });
    isErrorLogger !== true && await store.dispatch({
      type: 'ALERT_BAR',
      barStyle: 'warning',
      message: alertBarMessage
    });
  }

  // custom options to pass into logError
  const token = createCsrfHeader();
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate;
  const userEmail = user?.identityToken?.email || localStorage.getItem('userEmail') || null;

  logError(err, {
    requestGuidDetails,
    token,
    userEmail,
    axiosRequest,
    missingRequiredToken
  });
};

export const logFrontendError = (options) => {
  const { logMessage = '' } = options || {};
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate;
  logMessage && triggerLogError({
    logMessage,
    user,
    createCsrfHeader,
    axiosRequest
  });
};

export const closeSiteModal = () => store.dispatch({ type: 'MODAL', showModal: false });

export const showSiteModal = (title, content, options) => store.dispatch({
  type: 'MODAL',
  showModal: true,
  modalTitle: title,
  modalContent: content,
  options
});

export const fullPageLoader = bool => store.dispatch({
  type: 'TOGGLE_LOADER',
  loading: bool
});

export const isTokenValid = () => sharedIsTokenValid(store);

export const handleSignOutPortal = options => (
  handleSignOut({
    ...options,
    axiosRequest,
    signOutEndpoint: endpoint.user.signOut,
    store,
    handleClearLocalDB
  })
);

export const axiosDefaultUtils = { // default utils for apiCall
  handleApiError,
  createCsrfHeader,
  isTokenValid
};

export const axiosRequest = async (options, requestBody = {}) => {
  const { url, fullPageLoad = true } = options;
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const {
    urlWithNoParams,
    queryStringParams = null
  } = getUrlObject(url);
  return apiCall({
    ...options,
    ...(useStubData && {
      stubData: await getMockData(options),
      url: urlWithNoParams, // in local dev, send url without params
      queryStringParams
    })
  }, utils, requestBody);
};

export const fetchRequest = async (options, requestBody) => {
  // This should only be used if axiosRequest cannot be used
  // (eg, loading/downloading files using s3 upload/download links)
  const {
    url,
    instanceMethod,
    fullPageLoad = true
  } = options || {};
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const mockUrlMap = useStubData
    ? { // fetch instanceMethod - one of [arrayBuffer, blob, clone, formData, json, text]
      blob: endpoint.crab.v1.file.dummyDownloadUrl,
      arrayBuffer: endpoint.crab.v1.file.dummyDownloadUrl
    }
    : {};
  const mockOptions = useStubData
    ? { ...options, url: mockUrlMap[instanceMethod || 'blob'] }
    : {};
  const {
    urlWithNoParams,
    queryStringParams = null
  } = getUrlObject(url);
  return fetchCall({
    ...options,
    ...(useStubData && {
      stubData: await getMockData(mockOptions),
      url: urlWithNoParams, // in local dev, send url without params
      queryStringParams,
      ...mockOptions
    })
  }, utils, requestBody);
};

export const portalGetAllPageData = async (options) => {
  // NOTE: look at the apiGetAllPageData method for required params
  // util for getting all paged data in the PORTAL
  const {
    axiosRequestOptions = {}
  } = options || {};
  const {
    fullPageLoad
  } = axiosRequestOptions || {};
  const utils = {
    ...axiosDefaultUtils,
    ...fullPageLoad && { fullPageLoader }
  };
  const stubDataOptions = {
    ...(envIsLocalOnly() && {
      stubData: await getMockData(axiosRequestOptions)
    })
  };
  const apiResponse = await apiGetAllPageData(options, utils, stubDataOptions);
  return apiResponse;
};

export const handleAddToLocalDB = (key, value, options) => (
  addToLocalDB(key, value, { ...options, axiosRequest })
);

export const handleClearLocalDB = () => clearLocalDB({ axiosRequest });

export const getInnerAlertBarState = (options) => {
  const alertOptions = { ...options, axiosRequest };
  return sharedGetInnerAlertBarState(alertOptions);
};

// takes in a type of links and returns the links to be rendered
export const getLinks = async (type, viewAsMerchant) => {
  const {
    authenticate:
    {
      user: {
        isPartner,
        permissions
      }, isAuthenticated
    }
  } = store.getState();
  const enableApplications = isAuthenticated &&
    (isPartner && !viewAsMerchant) && permissions?.hasApplicationAccess;

  const navLinks = siteLinks(permissions, (isPartner && !viewAsMerchant));
  if (!type) { return null; }
  const links = [
    ...((type === 'header' && !isAuthenticated) ? [navLinks.signin] : []),
    ...(isAuthenticated && permissions?.hasDashboardAccess
      ? [navLinks.dashboard] : []),
    ...(isAuthenticated && isPartner && !viewAsMerchant ? [navLinks.merchants] : []),
    ...(enableApplications ? [navLinks.applications] : []),
    ...(isAuthenticated && permissions?.hasReportAccess
      ? [navLinks.reports] : []),
    navLinks.tickets,
    ...(type === 'header' && isPartner ? [navLinks.notifications] : []),
    // account at the end of this list to render at the end of the header when authenticated
    ...(isAuthenticated ? [navLinks.account] : [])
  ];
  return links;
};

export const getMyNotifications = async () => {
  const { authenticate = {} } = store.getState();
  const { user = {} } = authenticate || {};
  const { accessToken = {} } = user || {};
  const { csrfToken } = accessToken || {};
  const useMockData = useMockDbData(csrfToken); // For FTs
  // wait and check config results before making additional calls
  // there is no need to get notificaiton details if customer is not
  // signed up for notifications to begin with
  const notificationSettings = useMockData
    ? { data: mockNotificationConfigGet() }
    : await axiosRequest({
      url: endpoint.partnerPortal.notificationConfig,
      method: 'get',
      fullPageLoad: false
    });
  const hasConfigError = notificationSettings?.errorDetails instanceof Error;
  const formattedConfig = !isEmpty(notificationSettings?.data)
    ? transformData({
      data: { ...notificationSettings?.data },
      toSchema: 'frontendBellConfig',
      template: notificationConfigTemplate,
      version: '1.0'
    })
    : {};
  const actionSettings = formattedConfig?.actionRequiredReport || {};
  const crabSettings = formattedConfig?.crabNotification || {};
  const hasActionRequiredSettings = actionSettings?.noStarts ||
    actionSettings?.justTestProcessing ||
    actionSettings?.stoppedProcessing ||
    actionSettings?.terminated ||
    actionSettings?.runningHot ||
    actionSettings?.chargebackHealth ||
    actionSettings?.achReject;
  const hasCrabSettings = crabSettings?.approval ||
    crabSettings?.withdrawn ||
    crabSettings?.decline ||
    crabSettings?.pendsPublication ||
    crabSettings?.initialSubmission;
  if (hasActionRequiredSettings || hasCrabSettings) {
    const notificationBellApiRes = useMockData
      ? { data: mockNotificationBellGet() }
      : await axiosRequest({
        url: `${endpoint.partnerPortal.notificationBell}`,
        method: 'get',
        fullPageLoad: false
      });
    const hasBellApiError = notificationBellApiRes?.errorDetails instanceof Error;
    const formattedBellData = !isEmpty(notificationBellApiRes?.data)
      ? transformData({
        data: {
          backendData: notificationBellApiRes?.data,
          config: formattedConfig,
          hasActionRequiredSettings,
          hasCrabSettings
        },
        toSchema: 'frontend',
        template: notificationBellTemplate,
        version: '1.0'
      })
      : {};
    return {
      hasApiError: hasBellApiError,
      hasActionRequiredSettings,
      hasCrabSettings,
      totalCount: formattedBellData?.totalCount || 0,
      notifications: formattedBellData?.notifications || []
    };
  }
  return {
    hasApiError: hasConfigError || false,
    hasActionRequiredSettings: false,
    hasCrabSettings: false,
    totalCount: 0,
    notifications: []
  };
};

export const getAllAchDetails = async (options) => {
  const {
    multiCall = true, // REQUIRED - must use multiCall/firstPage
    firstPage = true, // REQUIRED - must use multiCall/firstPage
    firstPageData,
    customQueryParams,
    responseDataKey,
    requestGuid,
    fullPageLoad = false
  } = options || {};
  const apiRes = await portalGetAllPageData({
    dataKey: responseDataKey,
    firstPage,
    multiCall,
    customQueryParams,
    axiosRequestOptions: {
      fullPageLoad,
      url: endpoint.report.achDetails,
      method: 'get',
      requestGuid
    }
  });
  const { pagingInfo = {}, [responseDataKey]: resData } = apiRes?.data || {};
  const responseObject = Array.isArray(resData) && Array.isArray(resData[0]?.data)
    ? resData[0] ?? {}
    : resData ?? {};
  const { data: currentPageData, header } = responseObject || { };
  const allData = [
    ...(firstPageData ?? []),
    ...(currentPageData ?? [])
  ];
  if (firstPage) {
    const hasMoreRecords = !isEmpty(pagingInfo) &&
      pagingInfo?.totalNumberOfRecords > pagingInfo?.pageSize;
    if (hasMoreRecords) {
      return getAllAchDetails({
        ...options,
        firstPageData: currentPageData || [],
        firstPage: false
      });
    }
  }
  return {
    ...apiRes,
    data: {
      allData, // includes ALL paged data merged & unformatted
      header: header ?? []
    }
  };
};

export const checkBoardingEnabled = async () => {
  const apiRes = await axiosRequest({
    url: endpoint.parameter.boardingEnabled,
    method: 'get',
    tokenRequired: false
  });
  if (apiRes?.data) {
    const isTrue = apiRes.data.boardingEnabled === 'boolean'
      ? apiRes.data.boardingEnabled
      : apiRes.data.boardingEnabled.toLowerCase() === 'true';
    await store.dispatch({
      type: 'APP_STATE',
      navLinks: {
        boardingEnabled: isTrue
      }
    });
    return isTrue;
  }
  await store.dispatch({
    type: 'APP_STATE',
    navLinks: {
      boardingEnabled: false
    }
  });
  return false;
};

export const getAllRelationships = (tree, options) => {
  // partner portal - uses the relationshipTree to get
  // a flat list of all relationships, including downlines
  const {
    subPartner = []
  } = tree || {};
  const {
    relationshipList = [],
    parent = null,
    depth = 0,
    includeBankDetails = false
  } = options || [];
  if (!isEmpty(subPartner)) {
    subPartner.map((downline) => {
      relationshipList.push({
        ...downline,
        title: downline.dba,
        value: downline.guid,
        riskProfile: downline.riskProfile || null,
        ...(includeBankDetails && {
          bank: downline.bank,
          bankName: downline.bankName,
          processor: downline.processor,
          processorName: downline.processorName
        }),
        isParent: !isEmpty(downline?.subPartner),
        ...(parent && { parent, depth })
      });
      if (!isEmpty(downline?.subPartner)) {
        const children = getAllRelationships(downline, {
          relationshipList,
          parent: { title: downline.dba, value: downline.guid },
          depth: depth + 1,
          includeBankDetails
        });
        relationshipList.concat(children);
      }
      return true;
    });
  }
  return relationshipList;
};

export const getRefunds = async (options) => {
  const { fullPageLoad = false, requestGuid } = options || {};
  const {
    authenticate: { user: { isPartner } = {} } = {},
    filterData: { activeFilters: { viewAsMerchant } = {} } = {}
  } = store.getState();
  const dataType = isPartner && !viewAsMerchant ? 'partnerData' : 'merchantData';
  const refundEndpoint = isPartner && !viewAsMerchant ? `${endpoint.partnerPortal.refund}` : `${endpoint.merchant.refund}`;
  const requestGuidValid = dataExists(requestGuid);
  const apiRes = requestGuidValid
    ? await axiosRequest({
      fullPageLoad,
      url: refundEndpoint,
      requestGuid
    })
    : {};
  const formatData = data => (dataExists(data)
    ? {
      data: data.data,
      header: {
        ...chartHeader,
        ...data.header,
        stacked: true,
        label: 'date',
        biaxial: ['count'],
        lines: ['count'],
        showLegend: true
      }
    }
    : {}
  );
  const formattedData = apiRes?.errorDetails instanceof Error || !requestGuidValid
    ? {}
    : formatData(apiRes?.data);
  await store.dispatch({
    type: 'DATA_UPDATE',
    [dataType]: { refunds: formattedData }
  });
  return { ...apiRes, formattedData };
};

export const getReserves = async (options) => {
  const { useMerchantEndpoint, fullPageLoad = false, requestGuid } = options || {};
  const dataType = useMerchantEndpoint ? 'merchantData' : 'partnerData';
  const requestGuidValid = dataExists(requestGuid);
  const apiRes = requestGuidValid
    ? await axiosRequest({
      fullPageLoad,
      url: endpoint.report.reserveDetail,
      requestGuid,
      config: {
        params: {
          yearMonthFrom: addMonthsToYearMonth(-6, getThisYearMonth())
        }
      }
    })
    : {};
  const formatData = data => (dataExists(data)
    ? {
      data: data.data,
      header: {
        ...chartHeader,
        ...data.header,
        showLegend: true,
        stacked: true,
        currency: true,
        label: 'date',
        negatives: true
      }
    }
    : {}
  );
  const formattedData = apiRes?.errorDetails instanceof Error || !requestGuidValid
    ? {}
    : formatData(apiRes?.data?.chart);
  await store.dispatch({
    type: 'DATA_UPDATE',
    [dataType]: { reserves: formattedData }
  });
  return { ...apiRes, formattedData };
};

export const getPagedTickets = async (options) => {
  const { firstPage = true, axiosOptions = {}, customQueryParams } = options || {};
  const apiRes = await portalGetAllPageData({
    dataKey: 'ticketList',
    axiosRequestOptions: {
      fullPageLoad: false,
      url: `${endpoint.partnerPortal.ticket}`,
      method: 'get',
      ...axiosOptions
    },
    firstPage,
    multiCall: true,
    ...(!isEmpty(customQueryParams) && { customQueryParams })
  });
  return apiRes;
};

export const closeFormConfirm = (formInProgress, action) => (formInProgress
  ? swal({
    title: `Closing form, are you sure?`,
    text: 'It looks like you made some changes.',
    buttons: ['Keep editing', `Yes, cancel all changes`],
    className: 'swal-corvia-warning',
    dangerMode: true,
    icon: 'warning',
    closeOnClickOutside: false,
    closeOnEsc: false
  }).then(async (result) => {
    const canClose = !!result;
    return { sidebarOpen: !canClose, formInProgress: !canClose, action };
  }) : { sidebarOpen: false, formInProgress: false, action });

export const getPartnerHierarchy = async (relationshipGuid, options) => {
  const { fullPageLoad = false, relationship = {} } = options || {};
  if (!isEmpty(relationshipGuid)) {
    const apiRes = await axiosRequest({
      fullPageLoad,
      requestGuid: { relationshipId: relationshipGuid },
      method: 'get',
      url: `${endpoint.partner.hierarchy}`
    });
    if (apiRes?.errorDetails instanceof Error) {
      return apiRes;
    }
    const dataFormatted = transformData({
      data: {
        apiData: apiRes?.data,
        relationship,
        relationshipId: relationshipGuid
      },
      toSchema: 'frontend',
      template: partnerHierarchyTemplate,
      version: '1.0'
    });
    return {
      ...(!fullPageLoad && { state: apiRes?.state }),
      ...dataFormatted
    };
  }
  return {};
};

const reduceRelationshipTree = (downlines, options) => {
  const {
    depth = 0,
    useMultiSelect,
    parent,
    partnerPortalAccessList,
    permission,
    noPermissionsRequired, // Pass `true` if permissions are not required to render the list
    relationshipRequirements,
    useNestedDownlines
  } = options || {};
  const formattedDownlines = downlines.reduce((acc, downline) => {
    const access = partnerPortalAccessList.find(
      accessListItem => accessListItem?.relationshipId === downline?.guid ||
      accessListItem?.relationshipId === downline?.value
    ) || {};
    const hasAccessPermission = access[permission] || noPermissionsRequired;
    const isAllowedRelationship = !isEmpty(relationshipRequirements)
      ? Object.entries(relationshipRequirements)
        .some(([allowedRiskProfile, allowedProcessors]) => {
          const currentProcessor = ignoreCase(downline.processor || downline.processorName || '');
          const processorMatch = !isEmpty(allowedProcessors) && !isEmpty(currentProcessor) &&
            allowedProcessors.map(p => ignoreCase(p)).includes(currentProcessor);
          const riskProfileMatch = !isEmpty(allowedRiskProfile) &&
            ignoreCase(allowedRiskProfile) === ignoreCase(downline.riskProfile || '');
          return processorMatch && riskProfileMatch;
        })
      : true;
    if (hasAccessPermission && isAllowedRelationship) {
      const hasDownlines = !isEmpty(downline.subPartner);
      const currentDepth = depth ?? 0;
      const formattedDownline = {
        ...downline,
        ...(useMultiSelect && {
          title: downline.dba,
          value: downline.guid,
          relationshipCode: downline.relationshipCode,
          riskProfile: downline.riskProfile,
          inactive: downline.inactive
        }),
        ...(useNestedDownlines && {
          depth: currentDepth,
          // if currentDepth is 0, then parent should NOT be included for MultiSelect
          parent: currentDepth > 0 && !isEmpty(downline.parent) ? downline.parent.guid : null,
          ...(hasDownlines && { isParent: true })
        }),
        ...(isEmpty(downline.crabConfigurationOptions) && !isEmpty(parent) && {
          // relationship's children should use same config options
          crabConfigurationOptions: parent.crabConfigurationOptions
        })
      };
      const nestedDownlines = useNestedDownlines && hasDownlines
        ? reduceRelationshipTree(downline.subPartner, {
          ...options,
          parent: formattedDownline,
          ...(useNestedDownlines && { depth: currentDepth + 1 })
        })
        : [];
      return acc.concat(formattedDownline, ...nestedDownlines);
    }
    return acc;
  }, []);
  return formattedDownlines;
};

export const getRelationshipsWithPermissionList = (permission, options) => {
  const { authenticate } = store.getState();
  const { user } = authenticate || {};
  const { partnerPortalAccessList, relationshipTree } = user || {};
  const {
    noPermissionsRequired, // Pass `true` if permissions are not required to render the list
    multiSelect,
    relationshipType,
    riskProfile,
    relationshipRequirements = {} // Map of allowed riskProfile to processorName array
  } = options || {};
  const relationshipTreeSelector = relationshipType || 'subPartner';
  const { [relationshipTreeSelector]: downlines } = relationshipTree || {};
  const filteredRelationships = !isEmpty(downlines)
    ? reduceRelationshipTree(downlines, {
      useMultiSelect: multiSelect || false,
      useNestedDownlines: relationshipTreeSelector === 'subPartner',
      partnerPortalAccessList,
      permission,
      noPermissionsRequired,
      relationshipRequirements
    })
    : [];
  const dedupedRelationships = dedupeList(filteredRelationships);
  if (ignoreCase(riskProfile) === 'preferred') { return getPreferredRelationships(dedupedRelationships); }
  return dedupedRelationships;
};

export const getMerchantsWithPermissionList = (permission) => {
  const {
    authenticate: { user: { merchantGuidToDba, isPartner } },
    filterData: { activeFilters: { viewAsMerchant } }
  } = store.getState();
  if (isPartner && !viewAsMerchant) {
    const filteredRelationships = getRelationshipsWithPermissionList(permission);
    const filteredMerchants = filteredRelationships
      .reduce((acc, merchantList) => ([...acc, ...merchantList.merchant]), []);
    return filteredMerchants;
  }
  return merchantGuidToDba;
};

export const getDownlineList = () => {
  // 1) check if the data is already in the store
  const { authenticate = {}, dataUpdate } = store.getState();
  const { partnerData } = dataUpdate || {};
  const { downlineList } = partnerData || {};
  if (isEmpty(downlineList)) {
    // 2. if not, extract the list from relationship data
    const { user = {} } = authenticate || {};
    const { permissions } = user || {};
    const { hasDashboardAccess } = permissions || {};
    const permission = hasDashboardAccess ? 'dashboardAccess' : null;
    const list = getRelationshipsWithPermissionList(permission);
    const dlList = isEmpty(list)
      ? []
      : list.map(row => ({
        value: row.guid,
        title: `${row.dba} (${row.totalMerchantCount}  MIDs)`,
        parent: row.parent,
        depth: row.depth
      }));
    // update the store with the collected list
    store.dispatch({
      type: 'DATA_UPDATE',
      partnerData: {
        downlineList: dlList
      }
    });
    // also return the collected list so we can use it right away without waiting on store update.
    return dlList;
  }
  // otherwise, return the data already in the store.
  return downlineList;
};

export const handleSetVarListPolling = (pollingOn, options) => {
  store.dispatch({
    type: 'DATA_UPDATE',
    varListPolling: { pollingOn: pollingOn ?? false }
  });
};

const toggleLoader = (loading = true) => (store.dispatch({ type: 'TOGGLE_LOADER', loading }));

const customSettingsUtils = () => ({ axiosRequest, toggleLoader });

const customSettingsOptions = options => ({
  ...options,
  structure: portalSettingsStructure
});

export const getCustomSettings = async (options) => {
  const allOptions = customSettingsOptions(options);
  const utils = customSettingsUtils();
  const apiRes = await getCustomSettingsShared(allOptions, utils);
  return apiRes;
};

export const saveCustomSettings = async (options, requestBody) => {
  const allOptions = customSettingsOptions(options);
  const utils = customSettingsUtils();
  const apiRes = await saveCustomSettingsShared(allOptions, utils, requestBody);
  return apiRes;
};
