import { createMockPartherHealth } from '@f1/shared/src/data/partner/partnerHealth';

export const mockPartnerHealthGetRes = (body, params, options) => {
  const { guid = [`"{}"`] } = options || {};
  const backendData = createMockPartherHealth(guid, options);
  return {
    [guid]: backendData
  };
};

export default mockPartnerHealthGetRes;
