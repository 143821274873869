
export const mockActionReportsGet = {
  reportsActive: true,
  reportFrequency: 'weekly',
  noStarts: true,
  justTestProcessing: false,
  stoppedProcessing: false,
  terminated: false,
  chargebackHealth: true,
  achReject: false,
  lastReportSendDate: '2021-08-21'
};

export default mockActionReportsGet;
