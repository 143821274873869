import {
  transition,
  borderRadius
} from '@f1/shared/src/_globals';
import '../../css/animations.css';

export const footerCSS = {
  section: {
    padding: '10px',
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column'
  },
  ulWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    gap: '10px'
  },
  ul: {
    display: 'flex',
    flex: 'auto',
    alignContent: 'center',
    flexDirection: 'column',
    listStyle: 'none',
    padding: '0',
    margin: '0',
    background: 'var(--color-secondary)',
    gap: '10px'
  },
  li: {
    margin: '0',
    flex: '1',
    position: 'relative',
    color: 'white',
    fontSize: '1.4rem',
    lineHeight: '1.5',
    padding: '0'
  },
  legal: {
    color: 'var(--color-whiteish)',
    fontSize: '1.1rem',
    lineHeight: '1.5',
    minWidth: '300px',
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center'
  },
  legalText: {
    opacity: '0.5'
  },
  liHeader: {
    position: 'relative',
    color: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 23%))',
    fontWeight: 'bold',
    borderBottom: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), calc(var(--color-secondary-lightness) + 23%))',
    fontSize: '1.5rem'
  }
};

export const sidebar = {
  wrap: {
    width: '20%',
    maxWidth: '120px',
    float: 'left',
    height: '100vh',
    overflow: 'hidden'
  },
  nav: {
    position: 'fixed',
    zIndex: '9',
    top: '0',
    height: '100vh',
    background: 'hsl(var(--color-secondary-hue), var(--color-secondary-saturation), 97%)',
    width: '20%',
    maxWidth: '120px',
    padding: '107px 0 7px',
    overflow: 'hidden',
    ...transition()
  },
  ul: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    fontSize: '2rem'
  },
  li: {
    display: 'block',
    marginBottom: '10px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flexShrink: 1
  },
  a: {
    color: 'var(--color-white)',
    padding: '5px 7px',
    fontSize: '1.4rem',
    display: 'block',
    whiteSpace: 'nowrap',
    position: 'relative',
    overflow: 'hidden'
  },
  active: {
    color: 'var(--color-text)',
    background: 'var(--color-whiteish)'
  },
  sectionNum: {
    display: 'inline-block',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    lineHeight: '32px',
    borderRadius: '50%',
    clear: 'both',
    float: 'left',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: 'var(--color-white)',
    background: 'var(--color-primary)'
  },
  linkText: {
    padding: '0 10px',
    display: 'block',
    height: '32px',
    lineHeight: '32px',
    ...transition()
  }
};

export const delegateCSS = {
  wrap: {
    background: 'var(--color-bg)',
    padding: '20px',
    ...borderRadius()
  },
  permissionList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  permissionCheckbox: {
    flex: '1',
    maxWidth: '225px',
    minWidth: '225px',
    marginBottom: '5px',
    lineHeight: '1.5'
  },
  permissionLabel: {
    cursor: 'default',
    height: '20px',
    lineHeight: '20px',
    margin: '5px 0'
  },
  subText: {
    fontSize: '1.2rem',
    lineHeight: '1.3',
    margin: '0 0 10px'
  }
};

const sharedFaq = {
  minHeight: '30px',
  paddingLeft: '40px',
  paddingTop: '10px',
  paddingBottom: '10px',
  backgroundPosition: 'left 5px top 25px',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '30px'
};

export const faqCSS = {
  questionWrap: {
    ...sharedFaq,
    color: '#000000',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' %3E%3Cg transform='matrix(92.2279,0,0,92.2279,8.74561,68.2815)' %3E%3Cpath fill='%23ebebeb' d='M0.625,-0.333C0.625,-0.264 0.615,-0.205 0.593,-0.155C0.571,-0.105 0.54,-0.067 0.501,-0.039L0.628,0.061L0.564,0.12L0.414,0.001C0.391,0.007 0.366,0.01 0.34,0.01C0.284,0.01 0.234,-0.004 0.191,-0.031C0.148,-0.059 0.114,-0.098 0.09,-0.149C0.066,-0.2 0.054,-0.259 0.053,-0.326L0.053,-0.377C0.053,-0.446 0.065,-0.506 0.089,-0.559C0.113,-0.611 0.146,-0.651 0.19,-0.679C0.233,-0.707 0.283,-0.721 0.339,-0.721C0.396,-0.721 0.447,-0.707 0.49,-0.679C0.533,-0.652 0.567,-0.612 0.59,-0.56C0.614,-0.508 0.625,-0.447 0.625,-0.378L0.625,-0.333ZM0.532,-0.378C0.532,-0.462 0.515,-0.527 0.482,-0.572C0.448,-0.617 0.401,-0.64 0.339,-0.64C0.28,-0.64 0.233,-0.618 0.199,-0.573C0.165,-0.527 0.148,-0.465 0.147,-0.385L0.147,-0.333C0.147,-0.251 0.164,-0.187 0.198,-0.14C0.232,-0.093 0.279,-0.07 0.34,-0.07C0.4,-0.07 0.447,-0.092 0.48,-0.136C0.514,-0.18 0.531,-0.243 0.532,-0.326L0.532,-0.378Z' style='fill-rule:nonzero;' /%3E%3C/g%3E%3C/svg%3E")`
  },
  answerWrap: {
    ...sharedFaq,
    marginBottom: '40px',
    backgroundColor: 'var(--color-whiteish)',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' %3E%3Cg transform='matrix(92.2279,0,0,92.2279,10.0516,74.0457)' %3E%3Cpath fill='%23ebebeb' d='M0.475,-0.186L0.177,-0.186L0.11,-0L0.014,-0L0.285,-0.711L0.367,-0.711L0.639,-0L0.543,-0L0.475,-0.186ZM0.206,-0.263L0.447,-0.263L0.326,-0.595L0.206,-0.263Z' style='fill-rule:nonzero;' /%3E%3C/g%3E%3C/svg%3E")`,
    ...borderRadius('8px')
  }
};

export const cbCalculatorCSS = {
  table: {
    width: '100%'
  },
  spacerRow: {
    height: '40px',
    background: 'transparent'
  },
  spacerTd: {
    border: 'none'
  },
  totalRow: {
    fontWeight: 'bold',
    borderTop: '1px solid #000000'
  },
  outputHeader: {
    color: 'white',
    background: '#5b9bd5'
  },
  messageDiv: {
    fontSize: '1.2rem',
    lineHeight: '1.2'
  }
};
